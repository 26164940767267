import React, { Component } from 'react';
import './Header.css';
import phone from './../../Assets/Images/phone_icon.png';
import uscountry from './../../Assets/Images/LinkUSLogo.png';
import ukcountry from './../../Assets/Images/uk-country.png';
import auscountry from './../../Assets/Images/aus.png';
import nzcountry from './../../Assets/Images/nz-flag.png';
import login from './../../Assets/Images/login-in.png';
import logout from './../../Assets/Images/signout.png';
import stlogo from './../../Assets/Images/st-logo.png';
import triangle from './../../Assets/Images/flat-triangle.svg';
//import { HashLink as Link } from 'react-router-hash-link';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { HashLink as Link } from 'react-router-hash-link';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    Dropdown,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal, ModalHeader,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';

import { CONFIG } from '../../utils/config';
import { Regions } from '../../utils/enum';
import axios from 'axios';
class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.togglealert = this.togglealert.bind(this);

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseEnter2 = this.onMouseEnter2.bind(this);
        this.onMouseLeave2 = this.onMouseLeave2.bind(this);
        this.onMouseEnter3 = this.onMouseEnter3.bind(this);
        this.onMouseLeave3 = this.onMouseLeave3.bind(this);
        this.state = {
            collapse: false, collapsesignin: false, collapseforgetpwd: false, collapse1: false, isOpen: false, isScrolling: false, dropdownOpen: false,
            dropdownOpen2: false, dropdownOpen3: false, modal: false, sighinerror: '', curSymb: '', regionId: '',
            isPayAsyouGo: false
        };
    }

    //Login Pop-up
    // togglesignin(){
    //     this.setState(state => ({ collapsesignin: !state.collapsesignin }));
    //     this.setState(state => ({ collapseforgetpwd: false }));
    //     this.myFormRef  && this.myFormRef.reset();
    //     const sighinerror ='';
    //     this.setState({ sighinerror });
    // }

    toggleforgetpwd() {
        this.setState(state => ({ collapseforgetpwd: !state.collapseforgetpwd }));
        this.setState(state => ({ collapsesignin: !state.collapsesignin }));
        this.form && this.form.reset();
        const sighinsucess = '';
        this.setState({ sighinsucess });

    }
    toggleforgetcancel() {
        this.setState(state => ({ collapseforgetpwd: !state.collapseforgetpwd }));
    }
    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }
    toggle1() {
        this.setState(state => ({ collapse1: !state.collapse1 }));
    }
    onMouseEnter() {
        this.setState({ dropdownOpen: true });
    }
    onMouseLeave() {
        this.setState({ dropdownOpen: false });
    }
    onMouseEnter2() {
        this.setState({ dropdownOpen2: true });
    }
    onMouseLeave2() {
        this.setState({ dropdownOpen2: false });
    }
    onMouseLeave3() {
        this.setState({ dropdownOpen3: false });
    }
    onMouseEnter3() {
        this.setState({ dropdownOpen3: true });
    }
    togglealert() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        // let regionId =  CONFIG.Region_Id;
        // //Region
        // axios.get(CONFIG.API_URL + 'home/region')
        // .then(response => {
        //     let curSymb = response.data.region.currencySymbol;
        //     this.setState({
        //         curSymb
        //     })
        // });

        //to know buying report/market
        let getpathName = window.location.pathname.split('/');
        let isPAYGvalid = [];
        for (var i = 0; i < getpathName.length; i++) {
            if (getpathName[i] === 'getonemarket') {
                isPAYGvalid.push(true);
            }
        }
        this.setState({ isPayAsyouGo: isPAYGvalid.length > 0 ? true : false })

    }
    handleSubmit(event, errors, values) {

        this.setState({ sighinerror: '' });
        const data = {
            Password: values.Password,
            Username: values.Username,
            RegionId: CONFIG.Region_Id
        }
        if (errors.length === 0) {
            this.setState({ isLoading: true });
            axios.post(CONFIG.API_URL + 'Account/securelogin', data)

                .then(res => {

                    if (res.status === 200) {
                        const getTokvalues = res.data;
                        this.props.loginToken(getTokvalues);
                        this.myFormRef && this.myFormRef.reset();
                        this.setState({ getTokvalues });
                        axios.defaults.headers.common["Authorization"] = 'Bearer ' + getTokvalues.token;
                        localStorage.setItem('accessKey', getTokvalues.token);
                        localStorage.setItem('getNames', getTokvalues.firstName);
                        localStorage.setItem('isPaid', getTokvalues.isPaid);
                        localStorage.setItem('userStatus', getTokvalues.status);
                        localStorage.setItem('userManual', getTokvalues.isManual);
                        localStorage.setItem('tempNoStoreCount', getTokvalues.tempNoOfStores);
                        // localStorage.setItem('instructionMsg', getTokvalues.instructionMsg);
                        localStorage.setItem('userType', getTokvalues.userType);
                        // localStorage.setItem('curSymb', this.state.curSymb);
                        // localStorage.setItem('userCdate', getTokvalues.createdDate);
                        // localStorage.setItem('displayMessage', getTokvalues.displayMessage);

                        if (getTokvalues.isPaid === 0) {
                            const { from } = this.props.location.state || { from: { pathname: "/my-account/" } };
                            this.props.history.push(from);
                        }
                        else {
                            const { from } = this.props.location.state || { from: { pathname: "/my-market/" } };
                            this.props.history.push(from);
                        }

                    }

                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    if (err.response.data.StatusCode === 400) {
                        this.myFormRef && this.myFormRef.reset();
                        const sighinerror = err.response.data.Message;
                        this.setState({ sighinerror });
                    }
                    else {
                        const sighinerror = err.response.data;
                        this.setState({ sighinerror });
                    }

                });

        }
        this.setState({ errors, values });

    }
    handleforgetSubmit(event, errors, values) {
        //   const sighinsucess ='';
        if (errors.length === 0) {
            this.setState({ isLoading: true });
            const data = {
                Username: values.Username,
                RegionId: CONFIG.Region_Id
            }
            axios.post(CONFIG.API_URL + 'account/ForgotPassword', data)
                .then(res => {

                    const issuccss = res.data;
                    this.setState({ isLoading: false });
                    if (issuccss === "success") {
                        this.setState({ sighinsucess: ["The password reset link has been sent to your email."] });
                        this.togglealert();
                        this.form && this.form.reset();
                    }

                })
                .catch((err) => {
                    this.setState({ isLoading: false });
                    this.form && this.form.reset();
                    const sighinsucess = err.response.data;
                    this.setState({ sighinsucess });
                    this.togglealert();
                });

        }
    }

    valueChanged() {
        this.setState({ sighinerror: '' });
    }

    resetAllValues() {

        this.myFormRef && this.myFormRef.reset();
        this.setState({ sighinerror: '' });

    }

    render() {
        const { sighinerror, sighinsucess, isPayAsyouGo } = this.state;
        return (
            <React.Fragment>
                <div className="header">
                    <div className="header-top-bar clearfix">
                        <div className="header-top-bar-navigation">
                            <span className="phone-no">
                                <img src={phone} className="logo-img" alt="Phone" />
                                <span>Sales/Support: {CONFIG.Sales}</span>
                            </span>
                        </div>

                        <ul className="header-top-bar-social">
                            {CONFIG.Region_Id === Regions.US ?
                                <li className="country-flag"><a href={CONFIG.UK_URL} title="UK visitors click here" rel="noopener noreferrer"><img src={ukcountry} className="uk-country" alt="UK Country" /></a></li>
                                :
                                <li className="country-flag"><a href={CONFIG.US_URL} title="US visitors click here" rel="noopener noreferrer"><img src={uscountry} className="uk-country" alt="US Country" /></a></li>
                            }
                            <li className="country-flag"><a href={CONFIG.AU_URL} title="Australian visitors click here" rel="noopener noreferrer"><img src={auscountry} className="aus-country" alt="Aus Country" /></a></li>
                            <li className="country-flag"><a href={CONFIG.NZ_URL} title="NZ visitors click here" rel="noopener noreferrer"><img src={nzcountry} className="aus-country" alt="NZ Country" /></a></li>
                            <li><a href="https://www.linkedin.com/company/stortrack?trk=biz-brand-tree-co-name" target='_blank' rel="noopener noreferrer"><span className="icon-linkedin"></span></a></li>
                            <li><a href="https://www.twitter.com/StorTrack" target='_blank' rel="noopener noreferrer"><span className="icon-twitter"></span></a></li>
                            <li><a href="https://www.facebook.com/StorTrack" target='_blank' rel="noopener noreferrer"><span className="icon-facebook"></span></a></li>
                            <li>
                                <a href={CONFIG.STK_URL + "signin.html"} /*onClick={this.togglesignin.bind(this)}*/ rel="noopener noreferrer">Sign In <img src={login} className="mouse-out" alt="Login" /><img src={logout} className="mouse-in" alt="Logout" /></a>
                            </li>
                        </ul>
                    </div>
                    {/* sign-in form */}

                    <div className="btm-header">
                        <div className="main-menu">
                            <Navbar light expand="md">
                                <a href={CONFIG.STK_URL + "index.html"} > <img src={stlogo} className="logo main-page-logo" alt="StorTrack" /></a>
                                <NavbarToggler onClick={this.toggle1} />
                                <Collapse isOpen={this.state.collapse1} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <Dropdown className="navlist" nav onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.dropdownOpen} toggle={this.navtoggle}>
                                            <DropdownToggle nav className="no-cursor" style={{ color: '#f15d47' }}>
                                                Solutions
                                    </DropdownToggle>
                                            <DropdownMenu className="wsmenu-submenu st-mega-menu">
                                                <img src={triangle} className="arrow-img" alt="" />
                                                <div className="menu-left">
                                                    <h5>FOR OPERATORS</h5>
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "products/optimize.html"}>Optimize <i class="arrow right"></i>
                                                        <span>Daily market intelligence platform</span>
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "data-services/API.html"} >API & Custom Reports <i class="arrow right"></i>
                                                        <span>Custom rate data and market insights</span>
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "products/stortrack-for-sitelink.html"} >StorTrack for SiteLink <i class="arrow right"></i>
                                                        <span>Direct data into your Sitelink account</span>
                                                    </DropdownItem>
                                                </div>
                                                <div className="menu-right">
                                                    <h5>FOR INVESTORS</h5>
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "products/explorer.html"} >Explorer <i class="arrow right"></i>
                                                        <span>Market analysis tool with instant reports</span>
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag={"a"} href={CONFIG.REPORTS_URL}>Instant Market Reports <i class="arrow right"></i>
                                                        <span>Comprehensive market insights for self-storage due diligence</span>
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "research-and-reports/HistoricalReport.aspx"}>Historical Rate Data <i class="arrow right"></i>
                                                        <span>Historical rate reports for any market</span>
                                                    </DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <NavItem>
                                            <a href={CONFIG.ResourcePage} target="_blank" className="nav-link">Resources</a>
                                        </NavItem>
                                        <NavItem>
                                            <a href={CONFIG.STK_URL + "about.html"} className="nav-link">About</a>
                                        </NavItem>
                                        <NavItem>
                                            <a href={CONFIG.STK_URL + "contact.html"} className="nav-link">Contact</a>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Navbar>
                        </div>
                        <div className="clear"></div>
                    </div>
                    <Collapse isOpen={this.state.collapsesignin} className="sign-in-form">
                        <h5 className="size-18 mrg-btm-10">
                            Sign in
                                </h5>
                        <div className="sign-in-fields">
                            <AvForm onSubmit={this.handleSubmit.bind(this)} ref={c => (this.myFormRef = c)}>
                                <AvField name="Username" placeholder="Username" onChange={this.valueChanged.bind(this)} type="email" validate={{
                                    required: { value: true, errorMessage: 'Username is required' },
                                    pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Username is Invalid' }
                                }} />
                                <AvField name="Password" placeholder="Password" onChange={this.valueChanged.bind(this)} type="password" validate={{
                                    required: { value: true, errorMessage: 'Password is required' }
                                }} />

                                <span className="sign-error">{sighinerror}</span>
                                <div className="sign-in-btn">
                                    <Link to="#" onClick={this.resetAllValues.bind(this)} className="reset-pwd">Reset</Link>
                                    <Button id="btn" className="submit-btn btn-design">Sign In</Button>
                                    <Link to="#" rel="noopener noreferrer" onClick={this.toggleforgetpwd.bind(this)} className="fwd-link" id="fwd-id">Forgot Password?</Link>
                                </div>

                            </AvForm>
                        </div>
                    </Collapse>
                    {/* forgot password form */}
                    <Collapse isOpen={this.state.collapseforgetpwd} className="sign-in-form" /*style={{ display: 'none' }}*/>

                        <h5 className="size-18 mrg-btm-10">
                            Forgot Password
                        </h5>
                        <p>Provide your email address and we will send<br />you a link to update your password.</p>
                        <div className="sign-in-fields">
                            <AvForm onSubmit={this.handleforgetSubmit.bind(this)} ref={cl => (this.form = cl)} >
                                <AvField name="Username" placeholder="Enter Your Email address" type="email" validate={{
                                    required: { value: true, errorMessage: 'Email id is required' }
                                }} />
                                <div className="sign-in-btn">
                                    <Link to="#" onClick={this.toggleforgetcancel.bind(this)} className="reset-pwd">Cancel</Link>
                                    <Button id="btn" className="submit-btn btn-design">Send</Button>
                                </div>
                            </AvForm>
                            <Modal isOpen={this.state.modal} toggle={this.togglealert} >
                                <ModalHeader toggle={this.togglealert}> {sighinsucess}</ModalHeader>
                                {/* <ModalBody>
                                   
                                </ModalBody> */}
                            </Modal>
                        </div>
                    </Collapse>
                </div>


            </React.Fragment>
        );
    }
}
export default withRouter(Header);