import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Home, ErrorPage, BuyReport, PartnerNewUser, SuccessPage } from '../Views';
import { DefaultLayoutRoute } from './RouterLayout';

class RouteComp extends Component {
    render() {
        return (
                <BrowserRouter>
                    <Switch>
                        {/** ":getonemarket? and :getmvp-market?" - 
                            1.it is a way of optional paramter decalration.
                            2.Also used for identify whether it redirect from Explorer PayAsYouGo Subscription or not 
                        */}
                        <DefaultLayoutRoute exact path="/:getonemarket?" component={Home} />
                        <DefaultLayoutRoute exact path="/home/:propertyid/:address/:city/:state/:zipcode" component={Home} />

                        <DefaultLayoutRoute  path="/buy-report/:UserStoreId/:SecureId/:MarketType/:getonemarket?" component={BuyReport} />
                        <DefaultLayoutRoute  path="/partner-user/:address/:city/:state/:zipcode/:company" component={PartnerNewUser} />
                        <DefaultLayoutRoute  path="/partner-user/:addressinvalid" component={PartnerNewUser} />
                        <DefaultLayoutRoute  path="/pay-success/:SecureId" component={SuccessPage} />
                        {/* <MarketLayoutRoute path="/market-dashboard/:storeid/view-details/:searchid" component={ViewDetails} /> */}
                        {/* Session Out Page */}
                        <DefaultLayoutRoute path="/page_expired" component={ErrorPage} />
                        <DefaultLayoutRoute path="/UnauthorizedAccess" component={ErrorPage} />
                         
                        {/* 404 Error */}
                        <DefaultLayoutRoute path="*" component={ErrorPage} status={404} />
                    </Switch>
                </BrowserRouter>
        );
    }
}

export default RouteComp;