import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import { Container } from 'reactstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { CONFIG } from '../../utils/config';
import axios from 'axios';
import './PartnerNewUser.css';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import NumberFormat from 'react-number-format';
import { HashLink as Link } from 'react-router-hash-link';
import PaymentModel from '../PaymentModel/PaymentModel.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
export default class PartnerNewUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            scale: CONFIG.DefaultRadius,
            address: this.props.match.params.address != 'empty' ? this.props.match.params.address : '',
            city: this.props.match.params.addressinvalid != 'nodata' ? this.props.match.params.city != 'empty' ? this.props.match.params.city.trim() : '' : '',
            state: this.props.match.params.state != 'empty' ? this.props.match.params.state : '',
            zipCode: this.props.match.params.zipcode != 'empty' ? this.props.match.params.zipcode : '',
            locationView: {},
            userStoreID: '',
            locationStoreList: [],
            latitude: '',
            longitude: '',
            searchType: '',
            repPrice: 0,
            Sec_Id: '',
            annualPrice: 0,
            isReportId: 3,
            addressFormat: '',
            snackOpen: false,
            snackMSg: '',
            invalidAddress: this.props.match.params.addressinvalid,
            vidModal: false,

        };
        this.radiusOnChange = this.radiusOnChange.bind(this);
        this.snackHandleclose = this.snackHandleclose.bind(this);
        this.openVidModal = this.openVidModal.bind(this);
        this.closeVidModal = this.closeVidModal.bind(this);
        // var s=this.props;
        // this.state.address = "5100 Roadrunner Lane";
        // this.state.city = "Austin";
        // this.state.state = "TX, US";
        // this.state.zipCode = "78749";
        this.child = React.createRef();

    }

 //Open sample video modal 
 openVidModal() {
    this.setState({ vidModal: true });
}

//Close sample video modal 
closeVidModal() {
    this.setState({ vidModal: false });
}

    componentDidMount() {
        //call get price function
        this.getPrice();
        //call get secure id
        this.getSecureId();
        //call get new partner user info
        this.getPartnerUser();

    }
    OpenPaymentModel() {
        //for address
        let addressDetail = this.props.match.params;
        let addressFormat = addressDetail.address ? addressDetail.address + ',<br/>' : '';
        addressFormat += addressDetail.address2 ? addressDetail.address2 + ',<br/>' : '';
        addressFormat += addressDetail.city ? addressDetail.city + ', ' : '';
        addressFormat += addressDetail.state ? addressDetail.state.split(',')[0] + ' ' : '';
        addressFormat += (addressDetail.zipcode ? addressDetail.zipcode : '');

        this.child.current.openDialog(this.state.annualPrice, this.state.Sec_Id, this.state.userStoreID, this.state.isReportId, addressFormat);
    }
    radiusOnChange(scalevalue, value) {
        let scale = value;
        //this.setState({ scale });this.setState({ isLoading: true });
        if (this.state.invalidAddress != 'nodata') {
            this.setState({ scale, isLoading: true }, () => { this.searchLocation() });
        }
        //this.getPartnerUser();

    }
    getSecureId() {
        //For secure id
        axios.get(CONFIG.Local_Host + 'home/selectsecureid/' + CONFIG.Region_Id)
            .then(response => {
                let Sec_Id = response.data.lookups.secureID;
                this.setState({ Sec_Id });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err);
                }
            });


    }

    getPartnerUser() {
        var data = {
            Radius: this.state.scale,
            Address: this.state.address,
            City: this.state.city,
            State: this.state.state,
            Zipcode: this.state.zipCode,
            MarketChoice: 1,
            RegionId: CONFIG.Region_Id
        }
        axios.post(CONFIG.API_URL + 'marketchoice/searchbygeometric', data)
            .then(response => {

                if (response.data.locationView) {
                    let result = response.data.locationView || {};
                    let userStoreID = result.userStoreId;
                    let latitude = result.latitude;
                    let longitude = result.longitude;
                    // if(latitude===null&&longitude===null)
                    // {
                    //     alert('No records')
                    // }
                    let searchType = result.searchType;
                    // this.setState({isLoading: false, userStoreID });
                    this.setState({ userStoreID, longitude, latitude, searchType }, () => { this.saveCoverage(userStoreID, result.searchType); });
                }
                else {
                    // this.setState({ isLoading: false });
                    // alert('Address not found!')
                    this.setState({ isLoading: false, snackOpen: true, snackMSg: 'Address not found!' });
                }


            })
            .catch((err) => {

                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    searchReportData(userStoreId) {
        var data = {
            UserStoreId: userStoreId,

        }
        axios.post(CONFIG.API_URL + 'marketchoice/searchreportdata', data)
            .then(response => {

                let locationView = response.data.locationReportOverview;

                this.setState({ locationView, isLoading: false });
            })
            .catch((err) => {

                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    saveCoverage(userStoreId, searchType) {
        var data = {
            MarketName: "",
            UserStoreId: userStoreId,
            Radius: this.state.scale,
            SearchType: this.state.searchType,
            RegionId: CONFIG.Region_Id,
            IsMarketViewUserStore: true
        }

        axios.post(CONFIG.API_URL + 'marketchoice/savemarketcoverage', data)
            .then(response => {

                let isSuccess = response.data.result;
                if (isSuccess = "Success") {
                    this.searchReportData(userStoreId);
                }

                this.setState({ userStoreId });
            })
            .catch((err) => {

                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    searchLocation() {

        let data = {

            UserStoreID: this.state.userStoreID,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zipCode: this.state.zipCode,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            Radius: this.state.scale,
            RegionId: CONFIG.Region_Id,
            IsEdit: 1,
            MarketChoice: 1

        }

        axios.post(CONFIG.API_URL + 'marketchoice/searchbylocation', data)
            .then(response => {

                let locationStoreList = response.data.locationStoreList;
                this.setState({ locationStoreList }, () => { this.saveCoverage(this.state.userStoreID, null) });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 404) {
                    this.setState({ snackOpen: true, snackMSg: 'Location not found' });
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    getPrice() {
        //Get Price
        axios.get(CONFIG.Local_Host + 'home/getpricecalculation/' + CONFIG.Region_Id)
            .then(response => {

                let price = response.data.pricedet.priceList;
                let getRepPrice = price.filter(function (data) { return data.userType === 6 && data.reportID === 1 });
                let repPrice = getRepPrice.length > 0 ? getRepPrice[0].regularPrice : 0;
                let getAnnualPrice = price.filter(function (data) { return data.userType === 6 && data.reportID === 3 });
                let annualPrice = getAnnualPrice.length > 0 ? getAnnualPrice[0].regularPrice : 0;
                this.setState({ repPrice, annualPrice });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                alert(err);
            });
    }

    //Snack Close
    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };


    NumberFormat(number) {
        return new Intl.NumberFormat().format(number);
    }

    render() {
        const { isLoading, locationView, repPrice, annualPrice, snackOpen, snackMSg, vidModal } = this.state;
        return (
            <div className="rr-home">
                {isLoading ? <div className="loader-wrap only-this-page"><div className="loading loading-center"></div></div> : ''}
                <div className="background-header only-lss-header" style={{ height: 'auto' }}>
                    <div class="overlay-bg flat"></div>
                    <h1 className="market-head">Market Reports</h1>
                    <h2 className="title-medium text-white" style={{ padding: '45px 0px' }}>
                        The data you need to make smart, informed investment decisions
                    </h2>
                </div>
                <div className="content-section mb-4 only-lss-body">
                    <Container>
                        <section>
                            <Row className="pt-2 remove-para-padding">
                                <Col md='4' className="p-0">
                                    <div>
                                        <h6>Included with your Market Report:</h6>
                                        <div className="each-report assign-left-none">
                                            <p>30-day <b>complimentary access to the interactive Explorer Platform</b></p>
                                            <p>Access to over 19 interactive data widgets to investigate all aspects of the market</p>
                                            <p>Interactive maps, lists, developments, demographics, spending info and more</p>
                                            {/* <p>Comparison tools for side-by-side analysis (1/3/5 mile)</p> */}
                                            <p>Three years historical rate and availability info at the store and unit level</p>
                                            <p>PLUS a flexible Report Builder so you can <b>build your own reports</b> and download. Full customization!</p>
                                            {/* <p>Analysis of local marketing activity</p> */}

                                        </div>
                                        <div className="btns-bottom text-center view-sample-btns">
                                            {/* <Button
                                                onClick={this.viewSample.bind(this, 0)}
                                                color="secondary"
                                                variant="contained"
                                                className="all-reports sample-reports">
                                                View Sample
                                                                        </Button> */}

                                           <Link to="/PdfFile/MarketReport.pdf" target="_blank" className="see-sample btn btn-secondary" style={{lineHeight: '30px', height: '32px'}}>Report Sample</Link>
                                            <a onClick={this.openVidModal.bind(this)} style={{color: '#fff'}} class="all-reports sample-reports btn btn-secondary">See How it Works</a>

                                        </div>
                                    </div>
                                </Col>
                                <Col md='8'>
                                    <div className="pl-3 pr-3 pb-3 pt-0">
                                        <div className="report-details partner-report">
                                            <div className="selected-lists" style={{ paddingBottom: '5px' }}>
                                                <h5 style={{ textAlign: 'center' }}>Market Address</h5>

                                                {this.state.invalidAddress != 'nodata' ?
                                                    <p style={{ textAlign: 'center' }} className="mb-0"><b>Market: {this.state.address ? this.state.address : 'N/A'}, {this.state.city ? this.state.city : 'N/A'}, {this.state.state ? this.state.state : 'N/A'} {this.state.zipCode ? this.state.zipCode : 'N/A'}</b></p> : <p style={{ textAlign: 'center' }} className="mb-0"><b>Address: {'N/A'}</b></p>}
                                                <div className="radius-label text-center mb-2">{this.state.scale} mile radius (change by moving slider)</div>
                                                <Slider className="r-slider"
                                                    defaultValue={this.state.scale}
                                                    valueLabelDisplay='on'
                                                    // valueLabelFormat={this.getcustomLabel}
                                                    onChangeCommitted={this.radiusOnChange}
                                                    aria-labelledby="discrete-slider-always"
                                                    step={1}
                                                    marks={[{
                                                        value: 1,
                                                        label: '1'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: '2'
                                                    },
                                                    {
                                                        value: 3,
                                                        label: '3'
                                                    },
                                                    {
                                                        value: 4,
                                                        label: '4'
                                                    },
                                                    {
                                                        value: 5,
                                                        label: '5'
                                                    },
                                                    {
                                                        value: 6,
                                                        label: '6'
                                                    },
                                                    {
                                                        value: 7,
                                                        label: '7'
                                                    },
                                                    {
                                                        value: 8,
                                                        label: '8'
                                                    },
                                                    {
                                                        value: 9,
                                                        label: '9'
                                                    },
                                                    {
                                                        value: 10,
                                                        label: '10'
                                                    }

                                                    ]}
                                                    min={1}
                                                    max={10}
                                                />
                                                <Row>
                                                    <Col md='6'>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">Number of Competitors</div>
                                                            <div className="values2">{locationView.numberOfCompetitors ? locationView.numberOfCompetitors : isLoading === false ? 'N/A' : ''}</div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md='6'>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">Inventory Off Market</div>
                                                            <div className="values2">{locationView.inventoryOffMarket ? <NumberFormat value={locationView.inventoryOffMarket} displayType={'text'} thousandSeparator={true} suffix={'%'} /> : isLoading === false ? 'N/A' : ''}</div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md='6'>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">Population Served</div>
                                                            <div className="values2">{locationView.population ? <NumberFormat value={locationView.population} displayType={'text'} thousandSeparator={true} /> : isLoading === false ? 'N/A' : ''}</div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md='6'>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">Median Household Income</div>
                                                            <div className="values2">{locationView.medianHouseHoldIncome ? <NumberFormat value={locationView.medianHouseHoldIncome} displayType={'text'} thousandSeparator={true} prefix={CONFIG.Currency_Symbol} /> : isLoading === false ? 'N/A' : ''}</div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md='6'>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">Sqft/Capita</div>
                                                            <div className="values2">{locationView.sqftCapita ? <NumberFormat value={locationView.sqftCapita} displayType={'text'} thousandSeparator={true} /> : isLoading === false ? 'N/A' : ''}</div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md='6'>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">No. of Current Developments in Market</div>
                                                            <div className="values2">
                                                                {this.state.userStoreID ? <Link className="no-cursor">included in report</Link> : <Link className="disabledOnly">included in report</Link>}
                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md='6'>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">Rate Trend (last 12 months)</div>
                                                            <div className="values2">
                                                                {locationView.priceTrend ? locationView.priceTrend > 0 ? '' + locationView.priceTrend + '%' : locationView.priceTrend + '%' : isLoading === false ? 'N/A' : ''}

                                                            </div>
                                                            {/* <div className="values2">{locationView.priceTrend ? <NumberFormat value={locationView.priceTrend} displayType={'text'} thousandSeparator={true} suffix={'%'} /> : 'N/A'}</div> */}
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md='6'>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">Supply Growth Forecast (next 12 months)</div>
                                                            <div className="values2"> {this.state.userStoreID ? <Link className="no-cursor">included in report</Link> : <Link className="disabledOnly">included in report</Link>}</div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                </Row>                                                
                                            </div>
                                            <div className="get-reports">
                                                    <Row className="pl-3 pr-3 pt-2 pb-0">
                                                        <Col md={12} class="p-0"><h5 style={{fontSize: '21px'}}><span className="special-price-off">Buy this Market Report now for the <b>Special Price of <NumberFormat value={annualPrice} displayType={'text'} thousandSeparator={true} prefix={CONFIG.Currency_Symbol} /></b></span><span className="coupon-strike">(reg <NumberFormat value={repPrice} displayType={'text'} thousandSeparator={true} prefix={CONFIG.Currency_Symbol} />)</span></h5></Col>
                                                        <Col md={12} class="p-0">
                                                            <div className="btns-bottom text-center">
                                                                {this.state.userStoreID ?
                                                                    <Button
                                                                        onClick={this.OpenPaymentModel.bind(this)}
                                                                        color="secondary"
                                                                        variant="contained"
                                                                        className="all-reports sample-reports" style={{marginBottom: '0px', marginTop: '10px'}}>
                                                                        Buy Report
                                                                </Button>
                                                                    : <Button
                                                                        color="secondary"
                                                                        variant="contained"
                                                                        className="all-reports sample-reports disabledOnly" style={{marginBottom: '0px', marginTop: '10px'}}>
                                                                        Buy Report
                                                            </Button>
                                                        }
                                                            </div>
                                                            <div className="btm-txt-lss">includes 30-day access to the Explorer Platform!</div>
                                                        </Col>
                                                    </Row>
                                                </div>                                            
                                        </div>
                                    </div>

                                </Col>
                            </Row>

                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={snackOpen}
                                className="snackbar bg-color-primary custom-snackbar"
                                maxsnack={3}
                                autoHideDuration={3000}
                                onClose={this.snackHandleclose}
                                ContentProps={{
                                    'aria-describedby': 'message-id',
                                }}
                                message={<span id="message-id">{snackMSg}</span>}
                                action={[
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        onClick={this.snackHandleclose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                ]} />



                        </section>
                    </Container>
                </div>
                {/* Sample Video Modal */}
                <Dialog
                    open={vidModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="sample-videos"
                >
                    <DialogTitle>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.closeVidModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <iframe width="550" height="315" src="https://www.youtube.com/embed/QadKbv_WyUw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </DialogContent>
                </Dialog>
                <div className="payment-hide">
                    <PaymentModel parm={this.props} ref={this.child} />
                </div>

            </div>
        );
    };
}