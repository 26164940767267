import React, { Component } from 'react';
import { Row, Col, Container, Button, Label } from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { CONFIG } from '../../utils/config';
import { Regions } from '../../utils/enum';
import axios from 'axios';
import './Home.css';
import '../AddMarketcmpopup/AddMarketcmpopup.css';
import IconButton from '@material-ui/core/IconButton';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/icons/List';
import LocationOn from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import { withStyles } from '@material-ui/core/styles';
import PAYG_PaymentModel from '../PaymentModel/PAYG_PaymentModel.js';
import sampleReport from './../../Assets/Images/sample-report.png';
import mapboxSampleReport from './../../Assets/Images/mapbox-sample-report.png';
import MapGL, { Layer, NavigationControl, Popup, Source, WebMercatorViewport } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
// import AddMarketcmpopup from '../AddMarketcmpopup/AddMarketcmpopup.js';

export default class CommonMarketDetailBox extends Component {
    static defaultProps = {
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=' + CONFIG.GoogleMapsApiKey + ''
    }
    constructor(props) {
        const defaultRegion = CONFIG.Region_Id === Regions.UK;
        super(props);
        this.state = {
            //marketchoiceData: [],
            marketChoice: "address", //0,
            isLoading: true,
            Sec_Id: '',
            RepPrice: '',
            StoreDetail: {},
            storeAddress: {},
            stateData: [],
            snackMSg: '',
            snackOpen: false,
            snackClr: 'snack-errors',
            scale: CONFIG.DefaultRadius,
            UserStoreID: 0,
            locationStoreList: [],
            locationView: {},
            centerLatitude: '',
            centerLongitude: '',
            stateData: [],
            AlertdialogType: null,
            AlertdialogOpen: false,
            tabvalue: 0,
            page: 0,
            pageCount: 0,
            pageCountMDT: 0,
            rowsPerPage: 10,
            selectedRadioStoreId: 0,
            searchbyStore: [],
            isPayAsyouGo: window.location.pathname === "/getonemarket" ? true : false,
            vidModal: false,
            viewport: {
                latitude: defaultRegion ? 54.2363 : 41.7517,
                longitude: defaultRegion ? -4.5364 : -107.2985,
                zoom: defaultRegion ? 5 : 3,
            },
            popupInfo: { show: false, latLng: null, description: null },
            mapData: [],
        };
        this.child = React.createRef();
        this.childPAYG = React.createRef();
        this.onhandleChange = this.onhandleChange.bind(this);
        this.snackHandleclose = this.snackHandleclose.bind(this);
        this.openVidModal = this.openVidModal.bind(this);
        this.closeVidModal = this.closeVidModal.bind(this);
        this.autoPopulateAddress = this.autoPopulateAddress.bind(this);
        this.mapRef = React.createRef();
        this.getbounds = true;
    }

    //Open sample video modal 
    openVidModal() {
        this.setState({ vidModal: true });
    }

    //Close sample video modal 
    closeVidModal() {
        this.setState({ vidModal: false });
    }

    //Tab section on Finded Stores
    tabhandleChange(event, newValue) {
        let centerLatitude, centerLongitude;
        if (newValue !== 0) {
            centerLatitude = this.state.searchbyStore.length > 0 ? this.state.searchbyStore[0].latitude : 37.0902;
            centerLongitude = this.state.searchbyStore.length > 0 ? this.state.searchbyStore[0].longitude : -95.7129;
        } else this.getbounds = true;
        this.setState({ tabvalue: newValue, centerLatitude, centerLongitude /*, showingInfoWindow: false */ }, () => {
            if (Number(this.state.tabvalue) === 1) {
                if (this.state.mapData.length > 0 && CONFIG.Region_Id === Regions.US) this.getBoundingValues(this.state.mapData);
            }
        });
    }

    //Snack Close
    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    // on Change  function
    onhandleChange(e) {
        let fileds = e.target.name === 'marketChoice' || this.state.AlertdialogType === 2 ? this.state : this.state.StoreDetail;
        if (this.state.AlertdialogType === 2) {
            if (e.target.name === 'mapCheck' || e.target.name === 'selectedRadioStoreId') {
                e.target.value = Number(this.state.selectedRadioStoreId) === Number(e.target.value) ? 0 : e.target.value;
            }
            e.target.name = 'selectedRadioStoreId';
        }

        fileds[e.target.name] = e.target.value;
        this.setState({ fileds });
    }

    //On Submit
    OpenMarketChoiceModel() {
        let Sec_Id = this.state.Sec_Id != null && this.state.Sec_Id ? this.state.Sec_Id : localStorage.getItem('IsSecureId');
        this.child.current.ModalClickOpen(Number(this.state.marketChoice), Sec_Id);
    }


    componentDidMount() {
        // Auto Populate address field on report page from LSS Listing page
        this.autoPopulateAddress();

        //For secure id
        this.getSecureId();

        //For Market Choice list
        // axios.get(CONFIG.API_URL + 'marketchoice/marketselectionreport/' + CONFIG.Region_Id)
        //     .then(response => {

        //         let marketchoiceData = response.data;

        //         this.setState({ marketchoiceData, isLoading : false });
        //     })
        //     .catch((err) => {
        //         this.setState({ isLoading: false });
        //         if (err.response.status === 401) {
        //             localStorage.removeItem('accessKey');
        //             const { from } = this.props.location.state || { from: { pathname: "/" } };
        //             this.props.history.push(from);
        //         }
        //         else {
        //             alert(err);
        //         }
        //     });
            if (!localStorage.getItem('accessKey') || ((document.location.origin + '/') === document.location.href)) {
                var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
                (function () {
                    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                    s1.async = true;
                    s1.src = '//js.hs-scripts.com/4297816.js';//'https://embed.tawk.to/5bbe622408387933e5bacf9e/default';
                    s1.charset = 'UTF-8';
                    s1.setAttribute('crossorigin', '*');
                    s0.parentNode.insertBefore(s1, s0);
                })();
            }

        // States
        axios.get(CONFIG.API_URL + 'marketchoice/selectuserstates/' + CONFIG.Region_Id)
            .then(response => {
                let stateData = response.data;
                this.setState({ stateData, backUpstateData: stateData, isLoading: false });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });

        //For Price Calculation
        axios.get(CONFIG.API_URL + 'home/getpricecalculation/' + CONFIG.Region_Id)
            .then(response => {
                let price = response.data.pricedet.priceList;
                let getRepPrice = [];
                if (this.state.isPayAsyouGo) {
                    getRepPrice = price.filter(function (data) { return data.userType === 10 && data.noOfStores === 1 });
                }
                else {
                    getRepPrice = price.filter(function (data) { return data.userType === 6 && data.reportID === 1 });
                }

                let RepPrice = getRepPrice.length > 0 ? getRepPrice[0].regularPrice : 0;
                this.setState({ RepPrice });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err);
                }
            });
    }

    autoPopulateAddress() {
        if (this.props.match.params.address && this.props.match.params.city && this.props.match.params.state && this.props.match.params.zipcode) {
            let StoreDetail = this.state.StoreDetail;
            let storeAddress = this.state.storeAddress;
            
            StoreDetail['propertyid'] = this.props.match.params.propertyid != 'empty' ? parseInt(this.props.match.params.propertyid) : ''; 
            StoreDetail['address'] = this.props.match.params.address != 'empty' ? this.props.match.params.address : '';
            StoreDetail['city'] =  this.props.match.params.city != 'empty' ? this.props.match.params.city.trim() : '';
            StoreDetail['state'] = this.props.match.params.state != 'empty' ? this.props.match.params.state + (CONFIG.Region_Id == Regions.US ? ", US" : ''): '';
            StoreDetail['zipcode'] = this.props.match.params.zipcode != 'empty' ? this.props.match.params.zipcode : '';
            storeAddress['propertyid'] = this.props.match.params.propertyid != 'empty' ? parseInt(this.props.match.params.propertyid) : ''; 
            storeAddress['address'] = this.props.match.params.address != 'empty' ? this.props.match.params.address : '';
            storeAddress['city'] =  this.props.match.params.city != 'empty' ? this.props.match.params.city.trim() : '';
            storeAddress['state'] = this.props.match.params.state != 'empty' ? this.props.match.params.state + (CONFIG.Region_Id == Regions.US ? ", US" : ''): '';
            storeAddress['zipcode'] = this.props.match.params.zipcode != 'empty' ? this.props.match.params.zipcode : '';
            
            this.setState({ StoreDetail, storeAddress });
        }
    }

    //Get Secure Id
    getSecureId() {
        //For secure id
        axios.get(CONFIG.API_URL + 'home/selectsecureid/' + CONFIG.Region_Id)
            .then(response => {
                let Sec_Id = response.data.lookups.secureID;
                localStorage.setItem('IsSecureId', Sec_Id);
                this.setState({ Sec_Id });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err);
                }
            });
    }

    handleValidSubmit(event, errors, values) {
        if (errors.length === 0) {
            //For Find Stores
            
            if (this.state.marketChoice === 'existStore') {

                var data = {
                    MarketName: values.MarketName,
                    StoreName: values.storeName,
                    Address1: values.address,
                    Address2: values.address,
                    City: values.city,
                    State: CONFIG.Region_Id == Regions.UK ?'':values.state,
                    Zipcode: values.zipcode,
                    RegionId: CONFIG.Region_Id,
                };
                if (values.city != '' || (values.state != '' && CONFIG.Region_Id != Regions.UK ) || values.zipcode != '') {
                    axios.post(CONFIG.API_URL + 'marketchoice/searchbystore', data)
                        .then(response => {

                            let searchbyStore = response.data;
                            let formattedData = [];
                            if (searchbyStore.length > 0 && CONFIG.Region_Id === Regions.US) {
                                formattedData = searchbyStore.map(val => {
                                    return {
                                      "id": val.storeId,
                                      "type": "Feature",
                                      "geometry": {
                                        "type": "Point",
                                        "coordinates": [Number(val.longitude), Number(val.latitude)]
                                      },
                                      "properties": { ...val }
                                    }
                                });
                            }
                            this.setState({ mapData: formattedData, searchbyStore, tabvalue: 0, isLoading: false, zoomLevel: 8, pageCountMDT: 0, AlertdialogOpen: true, AlertdialogType: 2 });
                            this.getbounds = true;
                        })
                        .catch((err) => {
                            this.setState({ isLoading: false, dblclkrest4: false });
                            if (err.response.status === 401) {
                                localStorage.removeItem('accessKey');
                                const { from } = this.props.location.state || { from: { pathname: "/" } };
                                this.props.history.push(from);
                            }
                            else if (err.response.status === 404) {
                                this.setState({ AlertdialogOpen: true, AlertdialogType: 1 });
                            }
                            else {
                                alert(err.response.data);
                            }
                        });
                }
                else {
                    this.setState({ isLoading: false, snackOpen: true, snackMSg: 'To search for Stores, one of these filters is mandatory to enter - City, '+(CONFIG.Region_Id != Regions.UK ? 'State, ' :" ") + CONFIG.Region_Zip + '.', snackClr: 'snack-info' });
                    // alert('To search for Stores, one of these filters is mandatory to enter - City, State, Zipcode.');
                }
            }
            else {
                var data = '';
                let searchbyGeomateric = []
                this.setState({ isLoading: true });
                if (this.state.marketChoice === "address") {
                    const {storeAddress } = this.state; 
                    let stateValue = CONFIG.Region_Id === Regions.UK ? false : storeAddress.state === values.state ? false : true;
                    let isValueChanged = (storeAddress.address === values.address && storeAddress.city === values.city && storeAddress.zipcode === values.zipcode) ? false : true;
                    data = {
                        MarketName: values.MarketName ? values.MarketName : '',
                        Address: values.address,
                        City: values.city,
                        State: CONFIG.Region_Id == Regions.UK ?'':values.state,
                        Zipcode: values.zipcode,
                        PropertyId: isValueChanged || stateValue ? null : this.state.StoreDetail.propertyid,
                        Radius: this.state.scale,
                        RegionId: CONFIG.Region_Id,
                        MarketChoice: 1,
                        UserStoreID: this.state.UserStoreID,
                    };
                    if (CONFIG.Region_Id === Regions.US) {
                        let filterData = this.state.stateData.filter(ft => ft.stateID === values.state)[0];
                        data["CountryCode"] = filterData['countryCode'];
                        data["StateName"] = filterData['state'];
                    }
                }
                else {
                    data = {
                        MarketName: values.MarketName ? values.MarketName : '',
                        Address: '',
                        City: '',
                        State: '',
                        Zipcode: '',
                        Latitude: values.Latitude,
                        Longitude: values.Longitude,
                        Radius: this.state.scale,
                        RegionId: CONFIG.Region_Id,
                        MarketChoice: 1,
                        UserStoreID: this.state.UserStoreID,
                    };
                }

                searchbyGeomateric = data;
                this.setState({ searchbyGeomateric });

                if ((((data.State|| CONFIG.Region_Id == Regions.UK) && (data.City != '' || data.Zipcode != '')) && this.state.marketChoice === "address") || this.state.marketChoice === "lat/long") {
                    axios.post(CONFIG.API_URL + 'marketchoice/searchbygeometric', data)
                        .then(response => {
                            if (response.data.locationView) {
                                let locationView = response.data.locationView || {};
                                let centerLatitude = locationView.latitude;
                                let centerLongitude = locationView.longitude;
                                let locationStoreList = response.data.locationStoreList;
                                let UserStoreID = locationView.userStoreId;
                                this.setState({ locationView, locationStoreList, centerLatitude, centerLongitude, isLoading: false, UserStoreID, zoomLevel: 9 }, () => { this.addCoverage() });
                            }
                            else {
                                this.setState({ AlertdialogOpen: true, AlertdialogType: 1, dblclkrest2: false, isLoading: false, zoomLevel: 9 })
                            }

                        })
                        .catch((err) => {
                            this.setState({ isLoading: false, dblclkrest2: false, findStoreEnable: false });
                            if (err.response.status === 401) {
                                localStorage.removeItem('accessKey');
                                const { from } = this.props.parm.location.state || { from: { pathname: "/" } };
                                this.props.parm.history.push(from);
                            }
                            else if (err.response.status === 404) {
                                this.setState({ AlertdialogOpen: true, AlertdialogType: 1 });
                            }
                            else if (err.response.status === 400) {
                                this.setState({ snackOpen: true, snackMSg: err.response.data.Message });

                            }
                            else {

                                this.setState({ snackOpen: true, snackMSg: err.response.data });
                            }
                        });
                }
                else {
                    this.setState({ isLoading: false, snackOpen: true, snackMSg: CONFIG.Region_Id === Regions.UK ? 'Either City or ' + CONFIG.Region_Zip + ' is mandatory' :'Either City or ' + CONFIG.Region_Zip + ' is mandatory to enter along with State field value!', snackClr: 'snack-info' });
                }
            }
        }
    }

    //save Find Store
    saveStore() {
        this.setState({ isLoading: true });

        var SearchedSD = this.state.searchbyStore.filter((data) => {
            return data.storeId === parseInt(this.state.selectedRadioStoreId)
        });

        var data = {
            UserStoreID: this.state.UserStoreID,
            MarketChoice: 2,
            storeId: SearchedSD[0].storeId,
            address: SearchedSD[0].address,
            city: SearchedSD[0].city,
            state: SearchedSD[0].state,
            zipCode: SearchedSD[0].zipCode,
            latitude: SearchedSD[0].latitude,
            longitude: SearchedSD[0].longitude,
            Radius: this.state.scale,
            RegionId: CONFIG.Region_Id,
            StoreName: SearchedSD[0].storeName,
            IsEdit: this.state.IsEdit
        };

        //To show the selected store name as Market name in next step
        let StoreDetail = this.state.StoreDetail;
        StoreDetail['MarketName'] = SearchedSD[0].storeName;

        axios.post(CONFIG.API_URL + 'marketchoice/searchbylocation', data)
            .then(response => {
                let locationStoreList = response.data.locationStoreList;
                let locationView = response.data.locationView;
                let centerLatitude = locationView.latitude;
                let centerLongitude = locationView.longitude;
                let UserStoreID = locationView.userStoreId;
                this.setState({ UserStoreID, locationStoreList, locationView, isLoading: false, centerLatitude, centerLongitude, zoomLevel: 9, StoreDetail },
                    () => { this.addCoverage() });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 404) {
                    this.setState({ snackOpen: true, snackMSg: 'Location not found', snackClr: 'snack-errors' });
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    addCoverage() {
        this.setState({ isLoading: true });
        //For Authorization
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        let mkName = this.state.StoreDetail.MarketName ? this.state.StoreDetail.MarketName : '';
        let msa = '';
        let city = '';
        let scale = this.state.scale;

        let data = {
            MarketName: mkName,
            UserStoreId: this.state.locationView.userStoreId,
            Radius: scale,
            SearchType: this.state.locationView.searchType,
            RegionId: CONFIG.Region_Id,
            CityName: city,
            MSAName: msa,
            IsEdit: this.state.IsEdit,
            IsMarketViewUserStore: true
        }

        axios.post(CONFIG.API_URL + 'marketchoice/savemarketcoverage', data)
            .then(response => {
                let searchbyGeolocation = response.data;
                this.setState({ searchbyGeolocation, isLoading: false });
                let SecureID = localStorage.getItem('IsSecureId');
                let marketCh = this.state.marketChoice === 'existStore' ? 2 : 1;
                if (SecureID != null && SecureID) {
                    if (this.state.UserStoreID != null && this.state.UserStoreID) {
                        let oneMarketUrl = this.state.isPayAsyouGo ? '/getonemarket' : '';
                        const { from } = this.props.location.state || { from: { pathname: "/buy-report/" + this.state.UserStoreID + "/" + SecureID + "/" + marketCh + oneMarketUrl } };
                        this.props.history.push(from);
                    }
                }
                else {
                    alert('The error due to secure id');
                }
            })
            .catch((err) => {

                this.setState({ isLoading: false, findStoreEnable: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                }
                else if (err.response.status === 404) {
                    this.setState({ AlertdialogOpen: true, AlertdialogType: 1 });
                }
                else if (err.response.status === 400 && err.response.data.Message === "User State not authorized.") {
                    this.setState({ AlertdialogOpen: true, AlertdialogType: 0 });
                }
                else {

                    this.setState({ dblclkrest: true, snackOpen: true, snackMSg: err.response.data.Message });
                    // alert(err.response.data);
                }
            });
    }

    //Dialgon Handle Back
    handleBack(backType) {

        if (backType === -1) { // Alert Dialog Close Button
            // let StoreDetail = this.state.StoreDetail;
            // StoreDetail.state = '';
            this.setState({ AlertdialogOpen: false, stateData: this.state.backUpstateData, AlertdialogType: null, selectedRadioStoreId: 0 });
        }
    }

    //Pagination functions
    handleChangePageMTD = (event, pageCountMDT) => {
        this.setState({ pageCountMDT });
    };

    handleChangeRowsPerPageMTD = event => {
        this.setState({ pageCountMDT: 0, 10: event.target.value });
    };
    //End of Pagination functions

    //google maps props
    CMap = withScriptjs(withGoogleMap(props =>
        <GoogleMap
            ref={this.mapRef}
            onTilesLoaded={this.setMapFitBounds}
            defaultZoom={this.state.zoomLevel}
            // defaultZoom={this.state.lm_marketType === 'MSA' ? 5 : this.state.lm_marketType === 'City' ? 8 : 9}
            defaultCenter={{ lat: parseFloat(this.state.centerLatitude), lng: parseFloat(this.state.centerLongitude) }}
            defaultOptions={{ zoomControl: true, fullscreenControl: false, streetViewControl: false, disableDefaultUI: true, mapTypeControl: false }}
        >
            {props.children}
        </GoogleMap>
    ));

    onMarkerClick = (storeid, address, storeName) => {
        this.setState({
            selectedPlace: storeid,
            activeMarker: storeName ? storeName : '',
            showingInfoWindow: true,
            address: address
        })
    };

    onMarkerClose = () => {
        this.setState({ showingInfoWindow: false });
    }

    openPAYGModal() {
        let Sec_Id = this.state.Sec_Id != null && this.state.Sec_Id ? this.state.Sec_Id : localStorage.getItem('IsSecureId');
        this.childPAYG.current.openDialog(1, this.state.RepPrice, Sec_Id, 0, '');
    }

    handleViewportChange = (viewport) => {
        this.setState({ viewport });
    }

    getBoundingValues = (points) => {
        if (points) {
            const paddingLength = window.innerHeight / 4;
            const pointsLong = points.map((point) => point.geometry.coordinates[0]);
            const pointsLat = points.map((point) => point.geometry.coordinates[1]);
            const applyToArray = (func, array) => func.apply(Math, array);
            const cornersLongLat = [
                [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
                [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
            ];
            const viewport = new WebMercatorViewport({
                width: window.innerWidth,
                height: window.innerHeight,
            }).fitBounds(cornersLongLat, {
                padding: { top: paddingLength, bottom: paddingLength, left: paddingLength, right: paddingLength },
            });
            const { longitude, latitude, zoom } = viewport;
            this.setState({
                viewport: {
                    ...viewport,
                    latitude: latitude,
                    longitude: longitude,
                    zoom: zoom - 1,
                },
            });
        }
    };

    handleMapClick = (event) => {
        let data = event.features.filter(ft => ft.layer.type === "circle")[0];
        if (data && data.layer.type === "circle") {
            this.setState({
                popupInfo: {
                  show: true,
                  latLng: { latitude: Number(data.properties.latitude), longitude: Number(data.properties.longitude) },
                  marker: data.properties,
                }
            });
        }
    }

    setMapFitBounds = () => {
        if (this.getbounds && this.state.searchbyStore.length > 0) {
            this.getbounds = false;
            let bounds = new window.google.maps.LatLngBounds();
            for (let i = 0; i < this.state.searchbyStore.length; i++) {
                let loc = new window.google.maps.LatLng(this.state.searchbyStore[i].latitude, this.state.searchbyStore[i].longitude);
                bounds.extend(loc);
            }
            this.mapRef.current.fitBounds(bounds);
        }
    }

    render() {
        let { /*marketchoiceData,*/ marketChoice, isLoading, RepPrice, StoreDetail, stateData,
            snackMSg, snackOpen, snackClr, AlertdialogType, AlertdialogOpen, selectedRadioStoreId,
            tabvalue, searchbyStore, pageCountMDT, isPayAsyouGo, vidModal, viewport, mapData, popupInfo } = this.state;

        //for find stores list
        let selectedRadioSId = selectedRadioStoreId ? parseInt(selectedRadioStoreId) : 0;

        //set Dialog Class as per the alert
        let dialogClass = AlertdialogType === 2 ? "mvp-add-market" : "mvp-add-market-alert";

        return (
            <div className="rr-home investment-decision">

                {isLoading ? <div className="loader-wrap only-this-page"><div className="loading loading-center"></div></div> : ''}
                <div className="background-header mvp-reports-landing">
                    <div class="overlay-bg flat"></div>
                    <p className="title-para">Informed investment decisions</p>
                    <h1 className="title-medium text-white" style={{ padding: '30px 0px 10px', fontWeight: "600", fontSize: "2.4em" }}>                        
                        Market Reports starting from {RepPrice ? CONFIG.Currency_Symbol + RepPrice : ''}
                    </h1>
                    <p className="title-para mt-0 mb-0" style={{fontSize: "1.1rem", fontWeight: 'normal'}}>Includes 30-day access to the StorTrack Explorer platform!</p>
                </div>
                <div className="content-section mb-4">

                    <Container>
                        <section>
                            <Row>                                
                                <Col md={4} className="p-0 mobile-prcing-col">
                                    <p className="mb-2" style={{ fontSize: '18px', fontWeight: '900' }}>Included in the PDF report:</p>
                                    <div className="pricing-section-home">
                                        <div className="box-desc">
                                        <ul className="landing-ul pl-0">
                                            {/* {isPayAsyouGo ? 
                                            <React.Fragment> */}
                                            {/* {CONFIG.Region_Id === Regions.US ?
                                            <li><span>Property profiles, featuring transaction, tax and debt history</span></li>
                                            : ""}
                                            <li><span>Interactive map, new developments, market demographics and income information</span></li>
                                            <li><span>Past and current store rate trends (up to three years)</span></li>
                                            <li><span>Comparison tools for side-by-side analysis (1/3/5 mile)</span></li>
                                            <li>Ready-to-download reports or option to build custom reports</li> */}
                                            <li><span>Summary of the Market, including list of stores, Net Rentable Sq Ft, Sq Ft per Captia, Planned Developments and more</span></li>
                                            <li><span>Demographics, including Population, Households and Median income</span></li>
                                            <li><span>Current and historical average rate trends (12 months)</span></li>
                                            <li><span>Rate Volatility historical overview (12 months)</span></li>
                                            <li><span>Current and historical inventory availablity (12 months)</span></li>

                                        <p className="mb-2 mt-2" style={{ fontSize: '15px', fontWeight: '500' }}>Additional insights in the Explorer platform for 30 days:</p>

                                            <li><span>Interactive Map including nearby businessess and colleges</span></li>
                                            {CONFIG.Region_Id === Regions.US ?
                                            <li><span>Property Profiles including transaction history, tax, debt and ownership details</span></li>
                                            : ''}
                                            <li><span>Current and historical rate trends (up to 3 years)</span></li>
                                            <li><span>Rate Volatility historical overview (up to 3 years)</span></li>
                                            <li><span>Current and historical inventory availablity (up to 3 years)</span></li>
                                            <li><span>Granular rate comparisons by store and unit type</span></li>
                                            <li><span>Custom Report Builder Tool</span></li>

                                            {/* </React.Fragment>
                                        :
                                            <React.Fragment>
                                                <li>Pricing and market inventory history including trends analyses</li>
                                                <li>Market supply metrics, including sqft/capita and more</li>
                                                <li>Current project development pipeline and supply change forecast</li>
                                                <li>Demographics and market spend analysis</li>
                                                <li>All current Stores in the Market with ownership and management information</li>
                                            </React.Fragment>
                                        } */}
                                        </ul>
                                        </div>
                                    </div>
                                    {/* <div className={isPayAsyouGo ? "btns-bottom text-center" : "btns-bottom text-center"}>
                                        <Button className="see-sample">See Sample</Button> */}
                                        {/* {isPayAsyouGo ?  */}
                                        {/* <a onClick={this.openVidModal.bind(this)} style={{color: '#fff'}} class="all-reports btn btn-secondary">View Video Sample</a> */}
                                        {/* :
                                            <React.Fragment>
                                                <a href="javascript:window.open('/PdfFile/MarketReport.pdf','_blank')" class="see-sample btn btn-secondary">See Sample</a>
                                                <Button className="all-reports dummytext">All Reports are {RepPrice ? CONFIG.Currency_Symbol + RepPrice : ''}</Button>
                                            </React.Fragment>
                                        } */}
                                    {/* </div> */}
                                </Col>
                                <Col md={2} style={{marginTop : "40px"}} className="mobile-view-report">
                                    <div className="text-center"><img src={CONFIG.Region_Id === Regions.US ? mapboxSampleReport : sampleReport} alt="View Sample Market Report" /></div>
                                    <div className="downloads-btns">
                                        {CONFIG.Region_Id === Regions.US 
                                            ? 
                                            <React.Fragment>
                                            <a href={CONFIG.US_URL + "pdf/MarketReport_Sample_US.pdf"} target="_blank" className="mt-2 download-clr-btn">View Sample Market Report US</a>
                                            <a href={CONFIG.US_URL + "pdf/MarketReport_Sample_Canada.pdf"} target="_blank" className="mt-2 download-clr-btn">View Sample Market Report Canada</a>
                                            </React.Fragment>
                                            // <a href="javascript:window.open('/MarketReport_Sample_US.pdf','_blank')" className="mt-2 download-clr-btn">View Sample Market Report</a>
                                            :
                                            <a href={CONFIG.US_URL + "pdf/MarketReport_Sample_UK.pdf"} target="_blank" className="mt-2 download-clr-btn">View Sample Market Report</a>
                                            // <a href="javascript:window.open('/MarketReport_Sample_UK.pdf','_blank')" className="mt-2 download-clr-btn">View Sample Market Report</a>
                                        }
                                    </div> 
                                </Col>
                                <Col md={6} className="right-cols right-desc-content look-up-markets">
                                    <p style={{ fontSize: '18px', fontWeight: 900 }} className="mb-1">Preview Any Market Before You Buy {isPayAsyouGo ? <button className="btn btn-secondary skip-this-stage" onClick={this.openPAYGModal.bind(this)}>Skip this Stage (set the market in Explorer)</button> : ''}</p>
                                    <p style={{ fontSize: '13px', fontWeight: 500 }} className="mb-2">Already have an Explorer account? <a href={CONFIG.STK_SIGNURL} style={{textDecoration: "underline"}}>Sign in</a> to explore a new market!</p>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Radio value={"address"} name='marketChoice' onClick={this.onhandleChange} />}
                                            label={'Enter an address'} checked={marketChoice === "address" ? true : false} />
                                        <FormControlLabel
                                            control={<Radio value={"lat/long"} name='marketChoice' onClick={this.onhandleChange} />}
                                            label={'Enter coordinates'} checked={marketChoice === "lat/long" ? true : false} />
                                        <FormControlLabel
                                            control={<Radio value={"existStore"} name='marketChoice' onClick={this.onhandleChange} />}
                                            label={'Look up an existing store'} checked={marketChoice === "existStore" ? true : false} />
                                    </FormGroup>

                                    <div className='billing-content cmn-cnt your-markets'>
                                        <Container className="p-0">
                                            {marketChoice === "lat/long" ?
                                                <AvForm onSubmit={this.handleValidSubmit.bind(this)}  >
                                                    <div className={"scrool-popup-view selected-choice-2"}>
                                                        <Col md="10" className={"pl-30-px mb-2"}>
                                                            <Label>Latitude</Label>
                                                            <AvField name="Latitude" type="text" onChange={this.onhandleChange}
                                                                validate={marketChoice === "lat/long" ? {
                                                                    required: { value: true, errorMessage: "Latitude is required" },
                                                                    pattern: { value: /^[-+]?([1-8]?\d(\.\d+)?|89(\.0+)?)$/, errorMessage: "Enter valid Latitude " }
                                                                } : ''} value={StoreDetail.latitude ? StoreDetail.latitude : StoreDetail.Latitude || ''} id="Latitude" placeHolder='Range from 0 to 90' />
                                                        </Col>
                                                        <Col md="10" className={"pl-30-px mb-2"}>
                                                            <Label>Longitude</Label>
                                                            <AvField name="Longitude" type="text" /*pattern = "/^-?([1-9]?[1-9]|[1-9]0)\.{1}\d{1,15}/g"*/ onChange={this.onhandleChange}
                                                                validate={{
                                                                    required: { value: true, errorMessage: "Longitude is required" },
                                                                    ///^[-+]?(1[0-7]?\d(\.\d+)?|[1-9]?\d(\.\d+)|179(\.0+)?)$/
                                                                    pattern: { value: /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/, errorMessage: "Enter valid Longitude " }
                                                                }} value={StoreDetail.longitude ? StoreDetail.longitude : StoreDetail.Longitude || ''} id="Longitude" placeHolder='Range from 0 to 180' />
                                                        </Col>
                                                    </div>
                                                    <div className="step_btn home">
                                                        <Col className="pl-0">
                                                            <Button className="next-btn">
                                                                Save and Go To Next
                                                        </Button>
                                                        </Col>
                                                    </div>
                                                </AvForm>
                                                :
                                                <AvForm onSubmit={this.handleValidSubmit.bind(this)}  >
                                                    <div className={"scrool-popup-view selected-choice-2"}>
                                                        {marketChoice === "existStore" ?
                                                            <React.Fragment>
                                                                <Label className="main-lable mb-2" style={{ fontWeight: '500', fontSize: '13px' }}>
                                                                    Search from a store in our database to set as your Market center point
                                                            </Label>

                                                                <Col md="10" className="pl-30-px mb-2">
                                                                    <AvGroup>
                                                                        <Label>Store Name</Label>
                                                                        <AvInput name="storeName" type="text" onChange={this.onhandleChange}
                                                                            value={StoreDetail.storeName || ''} placeHolder='For example: Public Storage' />
                                                                    </AvGroup>
                                                                </Col>

                                                            </React.Fragment>
                                                            : ''}
                                                        <Col md="10" className="pl-30-px mb-2">
                                                            <AvGroup>
                                                                <Label>Street Address</Label>
                                                                <AvInput name="address" onChange={this.onhandleChange} type="text" ref={el => this.inputState = el}
                                                                    value={marketChoice === 'address' || marketChoice === 'existStore' ? StoreDetail.address || '' : ''} id="address" placeHolder={'For example: '+CONFIG.PlaceHolAddress} />
                                                            </AvGroup>
                                                        </Col>
                                                        <Col md="10" className="pl-30-px mb-2">
                                                            <AvGroup>
                                                                <Label>City</Label>
                                                                <AvInput name="city" onChange={this.onhandleChange} type="text" ref={el => this.inputState = el}
                                                                    value={marketChoice === 'address' || marketChoice === 'existStore' ? StoreDetail.city || '' : ''} id="city" placeHolder={'For example: '+CONFIG.PlaceHolCity} />
                                                            </AvGroup>
                                                        </Col>
                                                        {CONFIG.Region_Id==Regions.UK ?
                                                            <Col md="10" className="pl-30-px mb-2">
                                                                    <Label>{CONFIG.Region_Zip}</Label>
                                                                    <AvField name="zipcode" onChange={this.onhandleChange} type="text" errorMessage={CONFIG.Region_Zip + " is required"} validate={marketChoice === 'existStore' || marketChoice === "address" ? {
                                                                        // required: { value: true },
                                                                        pattern: { value: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$', errorMessage: CONFIG.Region_Zip + ' is invalid ' },
                                                                        maxLength: { value: 10, errorMessage: CONFIG.Region_Zip + ' must below 10 characters' }
                                                                    } : ''}
                                                                        value={marketChoice === "address" || marketChoice === 'existStore' ? StoreDetail.zipcode : ''} id='zipcode' placeHolder={'For example: '+CONFIG.PlaceHolZip} />
                                                            </Col>
                                                            :
                                                            <Row>
                                                                <Col md="5" className="right-padding-a-m state-province pl-45-px pr-0">
                                                                    <Label>State/Province</Label>
                                                                    <AvField type="select" onChange={this.onhandleChange} name="state"
                                                                        value={marketChoice === 'address' || marketChoice === 'existStore' ? StoreDetail.state || '' : ''} id="state" ref={el => this.inputState = el}>
                                                                        <option value='' >--Select State/Province--</option>
                                                                        {stateData ? stateData.map((state) =>
                                                                            // selected={EditStoredata.state === state.stateID ? true : this.state.state === state.stateID ? true : false}
                                                                            <option className={state.isSubscriped === 1 ? '' : 'lighter-class'} key={state.stateID} disabled={state.stateID === '' ? true : false} value={state.stateID}>{state.state}</option>
                                                                        ) : ''}
                                                                    </AvField>
                                                                </Col>
                                                                <Col md="5" className="left-padding-a-m pr-25-px">
                                                                    <Label>{CONFIG.Region_Zip}</Label>
                                                                    <AvField name="zipcode" onChange={this.onhandleChange} type="text" errorMessage={CONFIG.Region_Zip + " is required"} validate={marketChoice === 'existStore' || marketChoice === "address" ? {
                                                                        // required: { value: true },
                                                                        pattern: { value: '^[A-Za-z0-9]+$', errorMessage: CONFIG.Region_Zip + ' is invalid ' },
                                                                        maxLength: { value: 10, errorMessage: CONFIG.Region_Zip + ' must below 10 characters' }
                                                                    } : ''}
                                                                        value={marketChoice === "address" || marketChoice === 'existStore' ? StoreDetail.zipcode : ''} id='zipcode' placeHolder='For example: 94106' />
                                                                </Col>
                                                            </Row>
                                                        }                                                        
                                                    </div>
                                                    <div className="step_btn home">
                                                        <Col className="pl-0">
                                                            <Button className="next-btn">
                                                                {marketChoice === 'existStore' ? 'Find Stores' : 'Save and Go To Next'}
                                                            </Button>
                                                        </Col>
                                                    </div>
                                                </AvForm>
                                            }
                                        </Container>
                                    </div>                                    
                                </Col>                                
                            </Row>
                        </section>
                    </Container>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={snackOpen}
                    className="snackbar bg-color-primary custom-snackbar"
                    id={snackClr}
                    maxsnack={3}
                    autoHideDuration={3000}
                    onClose={this.snackHandleclose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackMSg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.snackHandleclose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]} />
                {/* Alert Dialog Box */}
                <Dialog className={'SingleMarket landing-stepper landing-popup mvp-landing-popup ' + dialogClass} open={AlertdialogOpen} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" align='center'>
                        <div>
                            <b className="h-pop-head">
                                {AlertdialogType === 1 ?
                                    marketChoice === 'lat/long' ?
                                        'Coordinates Not Found'
                                        :
                                        'Address Not Found'
                                    :
                                    AlertdialogType === 2 ?
                                        ""
                                        :
                                        'Alert !'
                                }
                            </b>
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className="closeModal"
                                onClick={this.handleBack.bind(this, -1)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className={AlertdialogType === 2 ? 'billing-content cmn-cnt your-markets' : ''}>
                        <div className="Alert-content">
                            {AlertdialogType === 1 ?
                                <React.Fragment>
                                    <div className="pt-0">
                                        {marketChoice === 'lat/long' ?
                                            <p>Please make sure you have entered valid Coordinates.</p>
                                            :
                                            <p>Please make sure you have entered a valid address.</p>
                                        }
                                        <p>If you believe you have received this message in error, please contact our support team at {CONFIG.Mail_Support} or {CONFIG.Sales}.</p>
                                    </div>
                                </React.Fragment>
                                :
                                AlertdialogType === 2 ?
                                    <React.Fragment>
                                        <Label className="main-lable">
                                            <h6>Please select a store as your Market's center point</h6>
                                        </Label>
                                        <AppBar position="static" className="tabs-pick-market">
                                            <Tabs value={tabvalue} onChange={this.tabhandleChange.bind(this)}>
                                                <Tab icon={<List />} Title="List View" />
                                                <Tab icon={<LocationOn />} Title="Map view" />
                                            </Tabs>
                                        </AppBar>
                                        <TabContainer>
                                            {tabvalue === 0 ?
                                                <Table className="table table-bordered custom-table ad-mar-tab">
                                                    <TableHead>
                                                        <TableRow className="table-header">
                                                            <TableCell></TableCell>
                                                            <TableCell>Store Name</TableCell>
                                                            <TableCell>Address</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {searchbyStore.length > 0 ? searchbyStore.slice(pageCountMDT * 10, pageCountMDT * 10 + 10).map((searchdata) =>
                                                            <TableRow key={searchdata.recNum}>
                                                                <TableCell padding="checkbox">
                                                                    <Radio checked={selectedRadioSId === searchdata.storeId ? true : false} className="radio-padding move-ch-top" onClick={this.onhandleChange} value={searchdata.storeId} name={searchdata.storeId} />
                                                                </TableCell>
                                                                <TableCell className="st-name">{searchdata.storeName}</TableCell>
                                                                <TableCell>
                                                                    {searchdata.address ? searchdata.address + ', ' : ''}
                                                                    {searchdata.city ? searchdata.city + ', ' : ''}
                                                                    {searchdata.state ? searchdata.state + ' ' : ''}
                                                                    {searchdata.zipCode ? searchdata.zipCode : ''}
                                                                </TableCell>
                                                            </TableRow>
                                                        ) :
                                                            <TableRow>
                                                                <TableCell colSpan="3" align='center' className="no-data">Stores not available.</TableCell></TableRow>
                                                        }
                                                    </TableBody>
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[1]}
                                                                colSpan={3}
                                                                count={searchbyStore ? searchbyStore.length : 0}
                                                                rowsPerPage={10}
                                                                page={pageCountMDT}
                                                                onChangePage={this.handleChangePageMTD}
                                                                onChangeRowsPerPage={this.handleChangeRowsPerPageMTD}
                                                                ActionsComponent={MyMarketWrapped}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                                :
                                                // <Fragment>
                                                CONFIG.Region_Id === Regions.US ?
                                                <div className="mapbox-full-width">
                                                    <MapGL
                                                        {...viewport}
                                                        onClick={this.handleMapClick}
                                                        onViewportChange={this.handleViewportChange}
                                                        mapboxApiAccessToken={CONFIG.MAPBOX_TOKEN}
                                                        mapStyle={"mapbox://styles/mapbox/streets-v11"}
                                                        attributionControl={false}
                                                        >
                                                            <NavigationControl className='zoom-plus-minus' showCompass={false} />
                                                            <Source id="store" type="geojson" data={{ type: 'FeatureCollection', features: mapData }}>
                                                                <Layer 
                                                                    id="store-layer"
                                                                    type='circle'
                                                                    paint= {{
                                                                    'circle-radius': 5,
                                                                    'circle-color': "#d90429",
                                                                    }}
                                                                 />
                                                            </Source>
                                                            {popupInfo.show &&
                                                                (<Popup {...popupInfo.latLng}
                                                                    onClose={() => this.setState({ popupInfo: { ...popupInfo, show: false } })}
                                                                >
                                                                    <div>
                                                                        <h3>{popupInfo.marker.storeName ? popupInfo.marker.storeName : ''}</h3>
                                                                        <p className='mb-2'>{popupInfo.marker.address ? popupInfo.marker.address + ', ' : ''}
                                                                            {popupInfo.marker.city ? CONFIG.Region_Id === Regions.UK ? popupInfo.marker.city + " " : popupInfo.marker.city + ", " : ""}
                                                                            {CONFIG.Region_Id === Regions.UK ? "" : popupInfo.marker.state ? popupInfo.marker.state + " " : ""}
                                                                            {popupInfo.marker.zipCode ? popupInfo.marker.zipCode : ''}</p>
                                                                        <p className='mb-0'>
                                                                            <input type="checkbox" key={popupInfo.marker.storeId} name="mapCheck"
                                                                                checked={selectedRadioSId === popupInfo.marker.storeId ? true : false}
                                                                                value={popupInfo.marker.storeId}
                                                                                onClick={this.onhandleChange}
                                                                            />
                                                                            {selectedRadioSId === popupInfo.marker.storeId ? 'Remove this Store' : 'Add this Store'}
                                                                        </p>
                                                                    </div>
                                                                </Popup>)}
                                                    </MapGL>
                                                </div>
                                                :
                                                <this.CMap
                                                    googleMapURL={this.props.googleMapURL}
                                                    loadingElement={<div style={{ height: `100%` }} />}
                                                    containerElement={<div style={{ height: `300px` }} />}
                                                    mapElement={<div style={{ height: `100%` }} />}
                                                >

                                                    {searchbyStore && searchbyStore.length > 0 ? searchbyStore.map((marker, index) => {
                                                        return (
                                                            <Marker
                                                                position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                                                                onClick={() => this.onMarkerClick(marker.storeId, marker.address, marker.storeName)}
                                                            >
                                                                {this.state.showingInfoWindow && this.state.selectedPlace === marker.storeId ?
                                                                    <InfoWindow
                                                                        onCloseClick={() => this.onMarkerClose()}
                                                                        visible={this.state.showingInfoWindow}
                                                                    >
                                                                        <div className="gmap-tooltip" >
                                                                            <h3>{this.state.activeMarker}</h3><p>{this.state.address ? this.state.address + ', ' : ''}
                                                                                {/* {marker.city ? marker.city + ', ' : ''}
                                                                                {marker.state ? marker.state + ' ' : ''} */}
                                                                                {marker.city ? CONFIG.Region_Id === Regions.UK ? marker.city + " ": marker.city + ", ": ""}
                                                                                {CONFIG.Region_Id === Regions.UK ? "" : marker.state ? marker.state + " ": ""}
                                                                                {marker.zipCode ? marker.zipCode : ''}</p>
                                                                            <p>
                                                                                <input type="checkbox" key={index} name="mapCheck"
                                                                                    checked={selectedRadioSId === marker.storeId ? true : false}
                                                                                    value={marker.storeId}
                                                                                    onClick={this.onhandleChange}
                                                                                />
                                                                                {selectedRadioSId === marker.storeId ? 'Remove this Store' : 'Add this Store'}
                                                                            </p>
                                                                        </div>
                                                                    </InfoWindow>
                                                                    : ''}
                                                            </Marker>
                                                        );
                                                    })
                                                        : <p className="no-data">Stores not available.</p>}
                                                    {/* <Circle
                                                    center={{ lat: parseFloat(this.state.centerLatitude), lng: parseFloat(this.state.centerLongitude) }}
                                                    radius={this.state.scale && this.state.searchbyStore.length > 0 ? Math.sqrt(this.state.scale) * 6000 : 0}
                                                    options={{
                                                        fillColor: "#000",
                                                        fillOpacity: 0,
                                                        // strokeColor: "#000",
                                                        // strokeOpacity: 1,
                                                        // strokeWeight: 1
                                                    }}
                                                /> */}

                                                </this.CMap>

                                            }
                                        </TabContainer>
                                        <Col style={{ paddingLeft: '0px' }}>
                                            <div className="step_btn">
                                                <Col className="pl-0">
                                                    <Button className="next-btn" disabled={selectedRadioSId === 0 ? true : false} onClick={this.saveStore.bind(this)}>
                                                        Save and Go To Next
                                                </Button>
                                                    <Button
                                                        onClick={this.handleBack.bind(this, -1)}
                                                        color="secondary"
                                                        variant="contained"
                                                        className="go-back-btn">
                                                        Go Back
                                                </Button>
                                                </Col>
                                            </div>
                                        </Col>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="pt-0">
                                            <p>You have not subscribed to the selected State. Please upgrade your subscription by clicking the button below.</p>
                                        </div>

                                        {/* <div className="">
                                        <div className="Subscription-btn" style={{textAlign: 'right'}}>
                                            <Link to="/my-account/" className="btn-save btn btn-secondary">Go to MyAccount</Link>
                                        </div>
                                        <div className="clear"></div>
                                    </div> */}
                                    </React.Fragment>
                            }
                        </div>
                    </DialogContent>
                </Dialog>
                {/*  End of Alert Dialog Box */}
                {/* Sample Video Modal */}
                <Dialog
                    open={vidModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="sample-videos"
                >
                    <DialogTitle>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.closeVidModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <iframe width="550" height="315" src="https://www.youtube.com/embed/QadKbv_WyUw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </DialogContent>
                </Dialog>
                {/* Pay-As-you-Go Payment Dialog Box */}
                {isPayAsyouGo ? < PAYG_PaymentModel parm={this.props} ref={this.childPAYG} /> : ''}
                {/* End of Pay-As-you-Go Payment Dialog Box */}

                {/* <AddMarketcmpopup parm={this.props} ref={this.child} /> */}
            </div>
        );
    };
}

//For Tab
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

// For Pagination
const actionsStyles = theme => ({
    tablepaggination: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing * 2.5,
    },
});

class MyMarket extends React.Component {

    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };


    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return (
            <div className={classes.tablepaggination}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>

        );
    }
}

const MyMarketWrapped = withStyles(actionsStyles, { withTheme: true })(
    MyMarket,
);