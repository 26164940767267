import React, { Component } from 'react';
import { Header, Footer } from './../../Core/index';


class DefaultLayout extends Component {
    render() {
        return (
            <div>
                <Header />
                    
                        {this.props.children}
                    
                <Footer />
            </div>
        );
    }
}

export default DefaultLayout;