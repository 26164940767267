//UK
export const CONFIG = {
  Region_Id: 3,
  Region_Zip: "Postal Code",
  Currency_Symbol: "£",
  Sales: "020 3048 3123",
  Mail_Support: "support@stortrack.co.uk",
  PlaceHolAddress: "River Park Road",
  PlaceHolCity: "Manchester",
  PlaceHolZip: "M402XP",
  PhoneMaxLength: 15,
  PhoneRegex: /^([^\s]{3})([^\s]{4})([^\s]{4})$/g,
  DefaultRadius: 2,
  API_URL: "https://explorerapi.stortrack.com/api/",
  Local_Host: "https://reportsapi.stortrack.com/api/",
  GoogleMapsApiKey: "AIzaSyCOyKBa2o4id-dopwQo7jGfVNtD0KJArXM",
  MapQuestKey: "YACBPORLjPNwG22g0A16czmgreWVIaGM",
  STK_URL: "https://www.stortrack.co.uk/",
  OPTZ_URL: "https://reports.stortrack.co.uk/",
  STK_SIGNURL: "https://www.stortrack.co.uk/signin.html",
  US_URL: "https://www.stortrack.com/",
  SeeOptions: "https://explorer.stortrack.co.uk/",
  AU_URL: "https://au.stortrack.com/",
  UK_URL: "https://www.stortrack.co.uk/",
  NZ_URL: "https://nz.stortrack.com/",
  StortrackLogin: "https://www.stortrack.co.uk/signin.html",
  ResourcePage: "https://www.stortrack.com/news",
  ForgotPwd_URL: "https://www.stortrack.co.uk/signin.html?req=fpass",
  LSS_Home: "https://listselfstorage.co.uk/",
  MAPBOX_TOKEN:
    "pk.eyJ1IjoiY2xvdWRzdXBwb3J0IiwiYSI6ImNrcDd6MzllZzA1N2IzMW9oNTcwOHh0ajAifQ.C2VDAibO99HHvq7_YluMmw",
};
