import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import './PaymentModel.css';
import { Container, Col, Row, Button, Label, Input, FormText } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import StepLabel from '@material-ui/core/StepLabel';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import CreditCardInput from 'react-credit-card-input';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import axios from 'axios';
import { CONFIG } from '../../utils/config';

function getSteps() {
    return ['Subscription Type', 'Create Account', 'Billing Address', 'Payment Details', 'Confirmation'];
}

export default class PAYG_PaymentModel extends Component{

    constructor(props) {
        super(props)
        this.state = {
            dialogOpen: false,
            snackOpen: false,
            activeStep: 1,
            UserDetail: {},
            PaymentDetail: {},
            errCard: '',
            phoneErr: '',
            emailErr: '',
            dialogcnt: '',
            StateCountry: '',
            stateAbb: '',
            stateData: [],
            phoneFieldBorder: '0',
            countryData: [],
            total_value: null,
            checkItOut: false,
            terms: false,
            focusValue: '',
            isLoading: false,
            checkErr: '',
            snackMSg: '',
            curSymb: CONFIG.Currency_Symbol,
            phone: '',
            dialogbox2:false,
            discountTotal : '', 
            couponErr: '', 
            validCoupon: '',
            secureId: '',
            UserStoreId : '',
            isReportId:'',
            marAddress: '',
            phoneMaxLength: CONFIG.PhoneMaxLength,
        };
        this.handleChange = this.handleChange.bind(this);
        this.snackHandleclose = this.snackHandleclose.bind(this);
        this.ValidateCard = this.ValidateCard.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    //To open Dialog box
    openDialog(getmarAddress, total_value, secureId, UserStoreId, isReportId) {
        let marAddress = getmarAddress === 1 ? '1 Market Report(s): '+ this.state.curSymb + total_value : getmarAddress;
        this.setState({ dialogOpen: true, activeStep: 1, total_value, secureId, UserStoreId, isReportId, marAddress});
    }

    phoneKeyPressed = (event) => {
        this.setState({ keyCode: event.keyCode });
    }

    //To Close Dialog box
    handleBack(backType) {
        //clicking the close function
        if(backType === 0){
            this.setState({ dialogOpen: false, activeStep: 0, UserDetail:{}, PaymentDetail:{}, checkedStates:[], errCard: '', checkErr:'', couponErr: '', emailErr: '', phoneFieldBorder: '0', Phone : '', phoneErr : '', dblclkrest1: false, dblclkrest2: false, dblclkrest: false, discountTotal : '', couponErr: '', validCoupon: '' });
        }
        else{//click the stepper back button
            if(this.state.activeStep === 0 || this.state.activeStep === 4){
                this.setState({ dialogOpen: false, UserDetail:{}, PaymentDetail:{}, checkedStates:[], errCard: '', checkErr:'', couponErr: '', emailErr: '', phoneFieldBorder: '0', Phone : '', phoneErr : '', dblclkrest1: false, dblclkrest2: false, dblclkrest: false, discountTotal : '', couponErr: '', validCoupon: '' });
                if(this.state.activeStep === 4){
                    let token = this.state.sucUser ? this.state.sucUser.token : ''; 
                    if (token) {
                        //to store in Reducer
                        this.props.loginToken(this.state.sucUser);
                        localStorage.setItem('accessKey', this.state.sucUser.token);
                        localStorage.setItem('getNames',  this.state.sucUser.firstName);
                        localStorage.setItem('isPaid',  this.state.sucUser.isPaid);
                        localStorage.setItem('userStatus', this.state.sucUser.status);
                        localStorage.setItem('userManual', this.state.sucUser.isManual);
                        localStorage.setItem('userType', this.state.sucUser.userType);
                        if (this.state.sucUser.isPaid === 0) {
                            const { from } = this.props.location.state || { from: { pathname: "/my-account/" } };
                            this.props.history.push(from);
                        }
                        else {
                            const { from } = this.props.location.state || { from: { pathname: "/my-market/" } };
                            this.props.history.push(from);
                        }
                    }
                }
            }
            else{
                this.setState({ activeStep: this.state.activeStep - 1, errCard: '', checkErr:'', couponErr: '', emailErr: '', phoneFieldBorder: '0', dblclkrest1: false, dblclkrest2: false, dblclkrest: false, Phone : '', phoneErr : ''});
            }
        }
        
    }

    //Snack Close
    snackHandleclose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
    };

    handleBlur(){
        this.ValidateCard();
    }

    ValidateCard() {
        let formvalid = false;
        if (!this.state.PaymentDetail.cardNumber || !this.state.PaymentDetail.SecurityCode || !this.state.PaymentDetail.expiry) {
            this.setState({ errCard: "Please enter missing card details" })
        }
        else if (this.state.PaymentDetail.expiry) {
            //Expiry date check for Card
            var getPaymonthyear = this.state.PaymentDetail.expiry.toString().split('/');
            let ExpiryMonth = getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim()) : '';
            let ExpiryYear = getPaymonthyear.length > 1 ? getPaymonthyear[1].trim() : '';
            var CurrentMonth = new Date().getMonth() + 1;
            var CurrentYear = new Date().getFullYear().toString().substr(-2);
            if (CurrentYear > ExpiryYear) {
                this.setState({ errCard: 'Please enter valid expiry year' });
                formvalid = false;
            }
            else {
                if (CurrentYear === ExpiryYear) {
                    if (CurrentMonth > ExpiryMonth || ExpiryMonth > 12) {
                        this.setState({ errCard: 'Please enter valid expiry month' });
                        formvalid = false;
                    } else {
                        if (this.state.PaymentDetail.SecurityCode) {
                            if (this.state.PaymentDetail.SecurityCode.length === 3 || this.state.PaymentDetail.SecurityCode.length === 4) {
                                this.setState({ errCard: '' });
                                formvalid = true;
                            } else {
                                this.setState({ errCard: 'Please enter valid CVV' });
                                formvalid = false;
                            }
                        }
                    }
                } else {
                    if (ExpiryMonth > 12) {
                        this.setState({ errCard: 'Please enter valid expiry month' });
                      }else{
                    if (this.state.PaymentDetail.SecurityCode) {
                        if (this.state.PaymentDetail.SecurityCode.length === 3 || this.state.PaymentDetail.SecurityCode.length === 4) {
                            this.setState({ errCard: '' });
                            formvalid = true;
                        } else {
                            this.setState({ errCard: 'Please enter valid CVV' });
                            formvalid = false;
                        }
                    }
                }
            }
        }
        }
        return formvalid;
    }

    handleChange(e) {

        let fields = this.state.activeStep === 3 && e.target.name !== 'CouponCode' ? this.state.PaymentDetail : this.state.UserDetail;
        let inputValid = true;
        let phoneErr = this.state.phoneErr;
        let phoneFieldBorder = this.state.phoneFieldBorder;

        if (e.target.name === 'Login') {
            this.setState({ focusValue: '', emailErr: '' });
        }

        //phone
        if(e.target.name === 'Phone'){
            var string = e.target.value;
            var replacedString = e.target.value.replace(/-/g, "").replace("(", "").replace(")", "");
            var numericRegex = /^([0-9]*)$/;
            var valid = numericRegex.exec(replacedString);
            var keyCode = this.state.keyCode;
            phoneErr = 'Phone is invalid';
            phoneFieldBorder = '1';

            if (valid) {
                string = replacedString;
                var regex = CONFIG.PhoneRegex;
                var match = regex.exec(string);
                if (match) {
                    match.shift();
                    match[0] = "(" + match[0] + ")";
                    string = match.join("-");
                    phoneErr = '';
                    phoneFieldBorder = '0';
                }
            } else {
                if (keyCode !== 8 && keyCode !== 46) {
                    string = this.state.UserDetail.Phone || '';
                }
            }

            if (string === "") {
                phoneErr = 'Phone is required';
                phoneFieldBorder = '1';
            }
            e.target.value = string;
        }
        
        if (inputValid === true) {

            fields[e.target.name] = e.target.value;
            if (e.target.name === "cardNumber" || e.target.name === "SecurityCode" || e.target.name === "expiry") {
                this.setState({ fields, errCard: '' });
            } else {
                this.setState({
                    fields, phoneErr, phoneFieldBorder, keyCode: ''
                });
            }

        }
       

        //State based Country
        if (this.state.UserDetail.BillingState !== undefined && this.state.UserDetail.BillingState !== '' && e.target.name === 'BillingState') {
            //spliting the country code from state id
            var Billing_state = e.target.value.toString().split(',');
            var sc_code = Billing_state.length > 0 ? Billing_state[1].trim() : '';
            var s_code = Billing_state.length > 0 ? Billing_state[0].trim() : '';

            // State's country code based country details
            let countryDetails = this.state.countryData.filter(function (data) {
                return data.countryCode === sc_code
            });

            //Adding Country name
            fields['BillingCountry'] = countryDetails[0].countryID;
            this.setState({ fields, StateCountry: countryDetails[0].country, stateAbb: s_code });
        }
    }

    handleValidSubmit(event, errors, values) {

        let formValid = true;
        if (this.state.activeStep === 3) {

            //credit card validation
            formValid = this.ValidateCard();

            let checkErr = '';
            if (this.state.checkItOut === false) {
                formValid = false;
                checkErr = 'Please accept terms and conditions';
            }
            this.setState({ checkErr });
        }
        //Phone number validation starts
        let phoneErr = '';
        let phoneFieldBorder = '0';
        if (this.state.UserDetail.Phone === undefined || this.state.UserDetail.Phone === "") {
            phoneErr = 'Phone is required';
            phoneFieldBorder = '1';
            formValid = false;
        } else if (this.state.UserDetail.Phone.length !== CONFIG.PhoneMaxLength) {
            phoneErr = 'Phone is invalid';
            phoneFieldBorder = '1';
            formValid = false;
        }
        this.setState({ phoneErr, phoneFieldBorder });
        //Phone number validation end
        
        //next stepper
        if (errors.length === 0) {
            if (formValid) {
                this.setState({ isLoading: true });
                //for next and  pay button double click restrict
                if (this.state.activeStep === 1) {
                    this.setState({ dblclkrest1: true });
                } else if (this.state.activeStep === 2) {
                    this.setState({ dblclkrest2: true });
                } else if (this.state.activeStep === 3) {
                    this.setState({ dblclkrest: true });
                }

                //For account create restriction
                if (this.state.activeStep !== 3) {
                    if (this.state.activeStep === 1) {
                        const data = { UserEmail: this.state.UserDetail.Login };
                        axios.post(CONFIG.API_URL + 'home/signinvalidation', data)
                            .then(response => {
                                if (response.data.message === 'No data') {
                                    this.setState(prevState => ({ activeStep: prevState.activeStep + 1, errCard: '', phoneErr: '', emailErr: '', checkErr:'' }));
                                } else {
                                    this.setState({ emailErr: 'Email already linked to an existing StorTrack account. Please use a different email address', dblclkrest1: false, focusValue: 'focused' });
                                    const x = document.getElementById('focused');
                                    x.focus();
                                }
                            });
                    }
                    else {
                        this.setState(prevState => ({ activeStep: prevState.activeStep + 1, errCard: '', phoneErr: '', emailErr: '', checkErr:'' }));
                    }
                    setTimeout(
                        function () {
                            this.setState({ isLoading : false });
                        }
                            .bind(this), 1000
                    );

                }
                else {

                    let UserDetail = this.state.UserDetail;
                    let PaymentDetail = this.state.PaymentDetail;

                    /* Optional Params*/
                    UserDetail['TempNoOfStores'] = 1;                   
                    UserDetail['TempBasicNoOfStores'] = 1;                   
                    UserDetail['TempPlusNoOfStores'] = 0;
                    /*end of Optional Params*/
                    UserDetail['BillType'] = 0;
                    UserDetail['BillingPhone'] = UserDetail.Phone;
                    UserDetail['BillingAddress2'] = UserDetail['BillingAddress2'] ? UserDetail['BillingAddress2'] : '';
                    UserDetail['UserType'] = 10 ;
                    UserDetail['Status'] = 1;
                    UserDetail["IsReceiveEmailAlerts"] = false;
                    UserDetail["PromoID"] = 0;
                    UserDetail["SelectedStates"] = [];
                    //UserDetail["LastName"] = '';
                    UserDetail["SecureID"] = this.state.secureId;
                    UserDetail["ReportID"] = this.state.isReportId;

                    var getPaymonthyear = PaymentDetail.expiry.toString().split('/');
                    PaymentDetail['ExpiryMonth'] = getPaymonthyear.length > 0 ? parseInt(getPaymonthyear[0].trim()) : '';
                    PaymentDetail['ExpiryYear'] = getPaymonthyear.length > 1 ? parseInt('20'.concat(getPaymonthyear[1].trim())) : '';
                    UserDetail['CouponCode'] = this.state.validCoupon ? UserDetail['CouponCode'] : '';
                    PaymentDetail['CardType'] = 'visa';
                    PaymentDetail['IsDefault'] = true;
                    PaymentDetail['isPayOrNot'] = false;

                    const data = {
                        UserDetail, PaymentDetail, UserStoreId : this.state.UserStoreId, RegionId : CONFIG.Region_Id
                    };
                    

                    axios.post(CONFIG.API_URL + 'subscription/addpayment', data) //'http://52.37.198.165:1213/api/subscription/add
                        .then(response => {
                            axios.post(CONFIG.Microservice_URL + 'data/toexplorer/' + this.state.UserStoreId)
                            .then(response => {
                                this.setState(prevState => ({ activeStep: prevState.activeStep + 1, errCard: '', phoneErr: '', sucUser: response.data, checkItOut: false, isLoading: false, checkErr:'' }));
                            })
                            .catch(err => {
                                this.setState(prevState => ({ activeStep: prevState.activeStep + 1, errCard: '', phoneErr: '', sucUser: response.data, checkItOut: false, isLoading: false, checkErr:'' }));
                            })
                        })
                        .catch(err => {

                            if (err.response.data.Message !== 'Payment Failed') {
                                if (err.response.data.Message === 'User with this email address already exists.') {
                                    this.setState({ snackOpen: true, snackMSg: 'Your Account have been created. Please login to futhur process.' , errCard: '', dblclkrest: false, checkErr:'' });
                                }
                                else {
                                    this.setState({ errCard: err.response.data.Message, dblclkrest: false, checkErr:'' });
                                }
                            }
                            else {
                                this.setState({ snackOpen: true, snackMSg: 'Payment Failed', dblclkrest: false, errCard: '', checkErr:'' });
                            }
                            this.setState({ isLoading: false });
                        });
                }
            }
        }
    }

    componentDidMount(){
        
        //Landing State list
        axios.get(CONFIG.API_URL + 'home/getlookupstates/'+ CONFIG.Region_Id)
            .then(response => {
                let initStateList = response.data.states;
                this.setState({
                    initStateList
                 });
            })
            .catch(err => {
                alert(err);
            });

        //States and Country
        let stateValue = CONFIG.Region_Id === 3 ? 1 : 0;
        axios.get(CONFIG.Local_Host + 'home/lookupdata/' + CONFIG.Region_Id+'/'+stateValue)
            .then(response => {
                let stateData = response.data.lookups.statesList;
                let countryData = response.data.lookups.countryList;
                this.setState({
                    stateData, countryData
                });
            })
            .catch(err => {
                
                this.setState({ isLoading: false });
            });
    }

    CouponApply() {

        let CouponCode = this.state.UserDetail.CouponCode;
        let couponValid = true;
        let validCoupon = this.state.validCoupon ? this.state.validCoupon : '';
        if (CouponCode === undefined || CouponCode === '') {
            couponValid = false;
            this.setState({ couponErr: 'PromoCode is required', validCoupon: '', discountTotal: '' });
        }

        if (couponValid) {
            const data = {
                'CouponCode': CouponCode,
                'UserType': 6,
                'RegionId' : CONFIG.Region_Id
            };
            axios.post(CONFIG.Local_Host + 'subscription/couponcode', data)
                .then(response => {
                    let discountTotal = response.data.isFixedPrice == 0 ? this.state.total_value - ((this.state.total_value / 100) * response.data.discountPercentage) : response.data.fixedPrice && response.data.fixedPrice != null ?response.data.fixedPrice : 'N/A';
                   // let discountTotal = this.state.total_value - ((this.state.total_value / 100) * response.data.discountPercentage);
                    discountTotal = discountTotal.toFixed(2);
                    let decimalCount = discountTotal.toString().split('.');
                    discountTotal = !decimalCount[1] ? discountTotal + '.00' : decimalCount[1].length === 1 ? discountTotal + '0' : discountTotal;
                    this.setState({ discountTotal, couponErr: '', validCoupon: CouponCode });
                })
                .catch(err => {
                    if (err.response.data.Message === "The coupon code you've entered has already expired!") {
                        this.setState({ couponErr: 'Expired PromoCode', validCoupon: '', discountTotal: '' });
                    }
                    else if(err.response.data.Message === "The coupon code you've entered is currently not active!"){
                        this.setState({ couponErr: "The promo code you've entered is currently not active!", validCoupon: '', discountTotal: '' });
                    }
                    else {
                        this.setState({ couponErr: 'Invalid Promo Code', validCoupon: '', discountTotal: '' });
                    }
                });
        }
    }

    //Payment Checkbox
    checkPayTerms() {
        this.setState({ checkItOut: !this.state.checkItOut });
    }

    // Terms and Conditions Dialog
    handleTermsaction = () => {
        this.setState({
            terms: !this.state.terms,
        });
    };

    render() {
        const steps = getSteps();
        const { isLoading, dialogOpen, activeStep, snackOpen, snackMSg,
        total_value, stateData, curSymb, stateAbb, marAddress} = this.state;
    
        /* for total value */
        var dectotVal = total_value && total_value !== null ? total_value.toString().split('.') : '';
        let dtotal_value = !dectotVal[1] ? total_value + '.00' : dectotVal[1].length === 1 ? total_value + '0' : total_value;
        
        //get valiud fileds
        let FullName = '';
        if(this.state.UserDetail.FirstName && this.state.UserDetail.LastName){
            FullName = this.state.UserDetail.FirstName + ' ' + this.state.UserDetail.LastName;
        }
        return (
            <div className="pay-as-you-go  landing-page">
                <section className="landing-body">
                    <Container className="landing-background">
                        <Dialog className='SingleMarket landing-stepper landing-popup mvp-landing-popup' open={dialogOpen} aria-labelledby="form-dialog-title">
                            {isLoading ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
                            <DialogTitle id="form-dialog-title" align='center'>
                                <div>
                                    <b className="h-pop-head">Purchase Market</b>
                                    <IconButton
                                        key="close"
                                        aria-label="Close"
                                        color="inherit"
                                        className="closeModal"
                                        onClick={this.handleBack.bind(this, 0)}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Stepper className="stepper-align" activeStep={this.state.activeStep} alternativeLabel >
                                        {steps.map((label, index) => (
                                            <Step key={label}>
                                                <StepLabel
                                                    className={this.state.activeStep > index ? 'MuiStepIcon-completed-cx' : ''}
                                                    completed={false}
                                                >{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <div>
                                    {activeStep === 1 ?
                                        <div className='user-details'>
                                            <Container>
                                                <AvForm onSubmit={this.handleValidSubmit.bind(this)}>
                                                    <Row>
                                                        <Col md="6" className="pr-0">
                                                            <Row className="scr-1">
                                                                <Col>
                                                                    <div className="subcontent-1 your-mvp" on="true">
                                                                        <Typography variant="h5" component="h5" gutterBottom className="st-subscription st-sub-change">
                                                                            Order Details
                                                                        </Typography>
                                                                        <Typography gutterBottom component="p" className="hom-text-bd font-sets" dangerouslySetInnerHTML={{__html:marAddress}}></Typography>
                                                                        <div className="bill-promo create-acc-promo height-94 pr-2">
                                                                            <Paper className="p-2 elevation0 apply-promo height-94" component="div">                                                                               
                                                                                <Col md="pt-4">
                                                                                    <Typography align="right" variant="h6" component="h6">
                                                                                        Promo code:
                                                                                    </Typography>
                                                                                </Col>
                                                                                <div className="apply-code">
                                                                                    <Input type="text" className="promoCode" name="CouponCode" onChange={this.handleChange} value={this.state.UserDetail.CouponCode || ''} />
                                                                                    <Button className="apply-btn" onClick={this.CouponApply.bind(this)}>Apply</Button>
                                                                                    <div className="clear"></div>
                                                                                    <FormText color='red'>{this.state.couponErr}</FormText>
                                                                                </div>
                                                                                {/* <Typography align="right" gutterBottom variant="h5" component="h5" className="st-subscription">
                                                                                    Total due today: <b className={this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? 'discount-strike' : ''}>{total_value !== null ? <NumberFormat value={dtotal_value} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b> <b>{this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? <NumberFormat value={this.state.discountTotal} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b>
                                                                                </Typography> */}
                                                                            </Paper>
                                                                        </div>
                                                                        <Typography component="p" align="right" className="hom-text-bd">
                                                                            Total due today: <b className={this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? 'discount-strike' : ''}>{total_value !== null ? <NumberFormat value={dtotal_value} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b> <b>{this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? <NumberFormat value={this.state.discountTotal} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b>
                                                                        </Typography>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div className="scr-2">
                                                                <Col className="background-left">
                                                                    <div className="subcontent-2">
                                                                        <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                            Included
                                                                        </Typography>
                                                                        <Typography className="bulleting" variant="caption" gutterBottom>
                                                                            Access over 19 interactive data widgets to investigate all aspects of the market
                                                                        </Typography>
                                                                        <Typography className="bulleting" variant="caption" gutterBottom>
                                                                            Interactive maps, lists, developments, demographics, spend info
                                                                        </Typography>
                                                                        <Typography className="bulleting" variant="caption" gutterBottom>
                                                                            Three years rate and availability at the store level
                                                                        </Typography>
                                                                        {/* <Typography className="bulleting" variant="caption" gutterBottom>
                                                                            Comparison tools for side-by-side analysis (1/3/5 mile)
                                                                        </Typography> */}
                                                                        <Typography className="bulleting" variant="caption" gutterBottom>
                                                                            Plus a flexible Report Builder so you can build your own reports and download. Full customization!
                                                                        </Typography>
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <Row className="enter-acc-details">
                                                                <Col md="12" className="pr-0">
                                                                    <Typography variant="h5" component="h5" gutterBottom>
                                                                        Enter Account Details
                                                                    </Typography>
                                                                </Col>
                                                                <Col md="12" className="pr-0">
                                                                    <Label> Email Address</Label>
                                                                    <AvField name="Login" type="text" validate={{
                                                                        required: { value: true, errorMessage: 'Email Address is required' },
                                                                        pattern: { value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/, errorMessage: 'Email Address is Invalid' }
                                                                        }} onChange={this.handleChange} value={this.state.UserDetail.Login || ''}
                                                                        id={this.state.focusValue === 'focused' ? 'focused' : ''}
                                                                            // ref={(input) => { this.nameInput = input; }}
                                                                    />
                                                                    <FormText className='errorMsg' color="red">{this.state.emailErr}</FormText>
                                                                </Col>
                                                                <Col md="12" className="pr-0">
                                                                    <Label> Password</Label>
                                                                    <AvField name="Password" type="password" validate={{
                                                                        required: { value: true, errorMessage: 'Password is required' },
                                                                        pattern: { value: '^(.{0,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{4,})|(.{1,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{3,})|(.{2,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{2,})|(.{3,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{1,})|(.{4,}(([a-zA-Z][^a-zA-Z])|([^a-zA-Z][a-zA-Z])).{0,})$', errorMessage: 'Passwords are case-sensitive, must be between 6 to 25 characters and contain at least 1 letter and 1 number or special character.' },
                                                                        minLength: { value: 6, errorMessage: 'Your Password must be 6 characters' },
                                                                        maxLength: { value: 25, errorMessage: 'Your Password must be 25 characters' }
                                                                        }}
                                                                    onChange={this.handleChange} />

                                                                </Col>
                                                                <Col md="12" className="pr-0">
                                                                    <Label>Confirm Password</Label>
                                                                    <AvField name="ConfirmPassword" type="password" validate={{
                                                                        required: { value: true, errorMessage: 'Confirm Password is required' },
                                                                        match: { value: 'Password', errorMessage: 'Mismatch Confirm Password' },
                                                                        maxLength: { value: 20, errorMessage: 'Your Password must be 20 characters' }
                                                                    }} />
                                                                </Col>

                                                                <Col md="12" className="pr-0">
                                                                    <Typography style={{marginTop: '10px', marginBottom: '5px'}} variant="h5" component="h3" gutterBottom>
                                                                        Enter Company and Contact Details
                                                                    </Typography>
                                                                </Col>
                                                                <Col md="6" className="pr-0">
                                                                    <Label>First Name</Label>
                                                                    <AvField name="FirstName" id="FirstName" validate={{
                                                                        required: { value: true, errorMessage: 'First Name is required' },
                                                                        pattern: {value: /^[A-Za-z\/\s\.'-]+$/, errorMessage: 'First Name is Invalid'}
                                                                        }}
                                                                    onChange={this.handleChange} value={this.state.UserDetail.FirstName || ''} />
                                                                </Col>
                                                                <Col md="6" className="pr-0">
                                                                    <Label>Last Name</Label>
                                                                    <AvField name="LastName" id="LastName" validate={{
                                                                        required: { value: true, errorMessage: 'Last Name is required' },
                                                                        pattern: {value: /^[A-Za-z\/\s\.'-]+$/, errorMessage: 'Last Name is Invalid'}
                                                                        }}
                                                                    onChange={this.handleChange} value={this.state.UserDetail.LastName || ''} />
                                                                </Col>
                                                                <Col md="12" className="pr-0">
                                                                    <Label> Company </Label>
                                                                    <AvField name="Company"
                                                                        onChange={this.handleChange} value={this.state.UserDetail.Company || ''} />

                                                                </Col>
                                                                <Col md="12" className="pr-0">
                                                                    <Label for="m_company">Phone</Label> <input type="text" name="Phone" id="phone" value={this.state.UserDetail.Phone ? this.state.UserDetail.Phone : ''} onKeyDown={this.phoneKeyPressed} onChange={this.handleChange} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
                                                                    <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText>
                                                                </Col>

                                                            </Row>
                                                        </Col>

                                                        <Col>
                                                            <Row>
                                                                <div className="step_btn">
                                                                    <Col className="pr-0">
                                                                        <Button
                                                                            onClick={this.handleBack.bind(this, 0)}
                                                                            color="secondary"
                                                                            variant="contained"
                                                                            className="go-back-btn">
                                                                            Go Back
                                                                        </Button>
                                                                        <Button className="next-btn" disabled={this.state.dblclkrest2 || this.state.dblclkrest1 ? true : false}>
                                                                                Next
                                                                        </Button>
                                                                    </Col>
                                                                </div>
                                                            </Row>
                                                        </Col>

                                                    </Row>
                                                </AvForm>
                                            </Container>
                                        </div>
                                    :
                                    activeStep === 2 ?
                                    <div className='billing-content cmn-cnt'>
                                        <Container>
                                            <AvForm onSubmit={this.handleValidSubmit.bind(this)}>
                                                <Row className="enter-acc-details">
                                                    <Col md="12">
                                                        <Typography variant="h5" component="h5" gutterBottom className="st-subscription">
                                                            Company and Contact Details
                                                        </Typography>
                                                    </Col>
                                                    <Col md="6" className="pr-0">
                                                        <Label> Email Address (username)</Label>
                                                        <AvField name="dEmail" type="email" disabled value={this.state.UserDetail.Login || ''} />
                                                    </Col>
                                                    <Col md="6" className="pr-0">
                                                        <Label>Full Name</Label>
                                                        <AvField name="dName" disabled value={FullName} />
                                                    </Col>
                                                    {this.state.UserDetail.Company ?
                                                        <Col md="6" className="pr-0">
                                                            <Label> Company </Label>
                                                            <AvField name="dCompany" disabled value={this.state.UserDetail.Company || ''} />
                                                        </Col>
                                                        : ''}
                                                    <Col md="6" className="pr-0">
                                                        <Label for="m_company">Phone</Label> <input type="text" name="Phone" id="phone" value={this.state.UserDetail.Phone ? this.state.UserDetail.Phone : ''} onKeyDown={this.phoneKeyPressed} onChange={this.handleChange} maxLength={this.state.phoneMaxLength} className={this.state.phoneErr ? "phoneValidate form-control" : "form-control"} />
                                                        <FormText className='errorMsg' color="red">{this.state.phoneErr}</FormText>
                                                    </Col>
                                                </Row>
                                                <Row className="">
                                                    <Col md="6" className="pr-0">
                                                        <Row className="enter-acc-details">
                                                            <Col md="12">
                                                                <Typography variant="h5" component="h5" gutterBottom className="st-subscription">
                                                                    Add Billing Address
                                                                </Typography>
                                                            </Col>
                                                            <Col md="12">
                                                                <Label> Address Line 1</Label>
                                                                <AvField name="BillingAddress" validate={{
                                                                    required: { value: true, errorMessage: 'Address Line 1 is required' },
                                                                    maxLength: { value: 150, errorMessage: 'Your BillingAddress must be 150 characters' }
                                                                }} onChange={this.handleChange} value={this.state.UserDetail.BillingAddress || ''} />
                                                            </Col>
                                                            <Col md="12">
                                                                <Label> Address Line 2</Label>
                                                                <AvField name="BillingAddress2" onChange={this.handleChange} value={this.state.UserDetail.BillingAddress2 || ''}
                                                                    validate={{ maxLength: { value: 150, errorMessage: 'Your BillingAddress2 must be 150 characters' } }} />
                                                            </Col>
                                                            <Col md="12">
                                                                <Label> City</Label>
                                                                <AvField name="BillingCity" validate={{
                                                                    required: { value: true, errorMessage: 'City is required' },
                                                                    maxLength: { value: 100, errorMessage: 'Your BillingCity must be 100 characters' }
                                                                }} onChange={this.handleChange} value={this.state.UserDetail.BillingCity || ''} />
                                                            </Col>
                                                            <Col md="6" className="pr-0 billing-state-province">
                                                                <Label> State/Province</Label>
                                                                <AvField type="select" name="BillingState" className="bs" validate={{
                                                                    required: { value: true, errorMessage: 'State/Province is required' }
                                                                }} onChange={this.handleChange} value={this.state.UserDetail.BillingState}>
                                                                    <option value='' >--Select State--</option>
                                                                    {stateData ? stateData.map((state) =>
                                                                        //state.stateID !== '' ? 
                                                                        <option key={state.stateID} className={state.stateID === '' ? "optHead" : ''} disabled={state.stateID === '' ? true : false} value={state.stateID}>{state.state}</option>
                                                                        // :''
                                                                    ) : ''}
                                                                </AvField>
                                                            </Col>
                                                            <Col md="6">
                                                                <Label> Zip/Postal Code</Label>
                                                                <AvField name="BillingZipCode" validate={{
                                                                    required: { value: true, errorMessage: 'Zip/Postal Code is required' },
                                                                    pattern: { value: '^[A-Za-z0-9]+$', errorMessage: 'Zip/Postal Code is invalid ' },
                                                                    maxLength: { value: 10, errorMessage: 'Your Zip/Postal Code must below 10 characters' }
                                                                }} onChange={this.handleChange} value={this.state.UserDetail.BillingZipCode || ''} />
                                                            </Col>
                                                            <Col md="12">
                                                                <Label> Country</Label>
                                                                <AvField name="BillingCountry" readOnly validate={{
                                                                    required: { value: true, errorMessage: 'Country is required' }
                                                                }} onChange={this.handleChange}
                                                                    value={this.state.UserDetail.BillingState ? this.state.StateCountry : ''} />
                                                            </Col>
                                                        </Row>
                                                    </Col>                                                    
                                                </Row>
                                                <Row>
                                                    <Col className="pr-0">
                                                        <div className="step_btn" style={{marginTop: '0px'}}>

                                                            <Button
                                                                onClick={this.handleBack.bind(this)}
                                                                color="secondary"
                                                                variant="contained"
                                                                className="go-back-btn">
                                                                Go Back
                                                            </Button>
                                                            <Button className="next-btn" disabled={this.state.dblclkrest2 || this.state.dblclkrest2 ? true : false}> Next</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </Container>
                                    </div>
                                    :
                                    activeStep === 3 ?
                                    <div className='payment-details'>
                                        <Container>
                                            <AvForm onSubmit={this.handleValidSubmit.bind(this)}>
                                                <Row className="">
                                                    <Col md="6" className="pr-0">
                                                        <div className="subcontent-3 pb-3">
                                                                <Typography variant="h5" component="h5" gutterBottom className="st-subscription">
                                                                    Billing Address
                                                            </Typography>
                                                                <Typography component="p" >
                                                                {this.state.UserDetail.BillingAddress || ''},
                                                            </Typography>
                                                            {this.state.UserDetail.BillingAddress2 !== undefined && this.state.UserDetail.BillingAddress2 !== '' ?
                                                                <Typography component="p" >
                                                                    {this.state.UserDetail.BillingAddress2 + ',' || ''}
                                                                </Typography>
                                                                : ''}
                                                            <Typography component="p" gutterBottom>
                                                                    {this.state.UserDetail.BillingCity || ''}, {stateAbb}  {this.state.UserDetail.BillingZipCode || ''}
                                                            </Typography>
                                                        </div>
                                                        <div className="paycontent">
                                                            <Typography variant="h5" component="h5" gutterBottom className="st-subscription">
                                                                 Add Payment Details
                                                            </Typography>
                                                            <Row className="enter-acc-details mar-min-top ">
                                                                <Col md="12">
                                                                    <Label>Name on Card</Label>
                                                                    <AvField name="cardName" id="Name" validate={{
                                                                        required: { value: true, errorMessage: 'Please enter the card name' }
                                                                    }} onChange={this.handleChange} value={this.state.PaymentDetail.cardName || ''} />
                                                                </Col>
                                                                <Col md="12">
                                                                    <Label>Card Credentials</Label>
                                                                    <CreditCardInput
                                                                        cardNumberInputProps={{ value: this.state.PaymentDetail.cardNumber || '', name: "cardNumber", placeholder: 'Card Number', onChange: this.handleChange, onBlur: this.handleBlur }}
                                                                        cardExpiryInputProps={{ value: this.state.PaymentDetail.expiry || '', name: "expiry", placeholder: 'MM/YY', onChange: this.handleChange, onBlur: this.handleBlur }}
                                                                        cardCVCInputProps={{ value: this.state.PaymentDetail.SecurityCode || '', name: "SecurityCode", placeholder: 'CVV', onChange: this.handleChange, onBlur: this.handleBlur }}
                                                                        fieldClassName={this.state.errCard !== '' ? "input is-invalid" : "input"}
                                                                    />
                                                                    <FormText className='errorMsg' color="red">{this.state.errCard}</FormText>
                                                                </Col>
                                                                <Col md="12" className="pt-1">
                                                                    <div className="terms_cond">
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox className="track-check-gl" name="checkItOut" onClick={this.checkPayTerms.bind(this)} checked={this.state.checkItOut} />
                                                                            }
                                                                            label={<span className="font-sz-13">I Agree to StorTrack's <Link to={'#'} onClick={this.handleTermsaction}>Terms</Link></span>}
                                                                        />
                                                                        <FormText className='errorMsg' color="red">{this.state.checkItOut === false ? this.state.checkErr : ''}</FormText>
                                                                        <div className="clear"></div>
                                                                    </div>
                                                                    <div className="termsandconditions">
                                                                        <Dialog onClose={this.handleTermsaction} aria-labelledby="customized-dialog-title"
                                                                            open={this.state.terms} className="tc_dialog">
                                                                            <DialogTitle id="customized-dialog-title" onClose={this.handleTermsaction}>
                                                                                <b>Terms</b>
                                                                                    <IconButton
                                                                                        key="close"
                                                                                        aria-label="Close"
                                                                                        color="inherit"
                                                                                        className="closeModal"
                                                                                        onClick={this.handleTermsaction.bind(this)}
                                                                                    >
                                                                                        <CloseIcon />
                                                                                    </IconButton>
                                                                            </DialogTitle>
                                                                            <DialogContent dividers='true'>
                                                                                            <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                COPYRIGHT AND USAGE AGREEMENT
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                This Subscription Agreement governs use of content StorTrack.com (and aliases). When used in this Agreement, "Service" means those premium content areas of the StorTrack.com site which you may have been authorized to access. By submitting your completed Registration Form, and using any part of the service, you are representing that you are 18 years old or older, and that you agree to be bound by all of the terms in this Subscription Agreement. You may print and keep a copy of this Agreement. Aggregate Intelligence, Inc may change the terms in this Subscription Agreement. When the terms are changed, Aggregate Intelligence, Inc will notify you by e-mail and/or online postings. The changes also will appear in this document, which you can access at any time by selecting "Terms" in footer section of the home page. StorTrack.com website after it has notified subscribers of a change in this Subscription Agreement is posted, you agree to be bound by all of the changes.
                                                                                    </Typography>
                                                                                            <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                PRIVACY OF INFORMATION
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                By clicking the "I Accept" button, you also are agreeing that Aggregate Intelligence, Inc may location information you provide as part of the subscription process and usage information, and provide aggregate statistical information about subscribers to advertisers, potential advertisers, and content licensors. Aggregate Intelligence, Inc also may use the information to inform you about other products and services, unless you notify Aggregate Intelligence, Inc that you do not wish to receive this information. Aggregate Intelligence, Inc also may deliver additional messages about the Service to you by e-mail.
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                As a general policy, Aggregate Intelligence, Inc does not disclose to third parties any specific personal identifying information about you, such as your name, address, phone number, or e-mail address ("Personal Identifying Information"). Aggregate Intelligence, Inc will not disclose any of your Personal Identifying Information to a third party without first notifying you and giving you an opportunity to elect not to have your Personal Identifying Information disclosed.
                                                                                    </Typography>
                                                                                            <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                ENTERPRISE CUSTOMERS
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                If Aggregate Intelligence, Inc and you or your employer have manually signed a paper version of a contract governing your use of the Service, or if you or your employer subscribe to the Service pursuant to an Enterprise Subscription Agreement, the terms in that manually signed, paper document or Enterprise Subscription Agreement are not amended by, and supersede, terms in this Subscription Agreement.
                                                                                    </Typography>
                                                                                            <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                FEES AND PAYMENTS
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                You can always find the current Subscription Fees and charges posted with the product information. Your subscription will continue for the term specified and will renew automatically until terminated by Aggregate Intelligence, Inc or until you notify Aggregate Intelligence, Inc by electronic mail of your decision to terminate your subscription or cancel the subscription from “My Account” page after Signing In. Your credit card will be charged for the total amount for any subscription package chosen, per the Checkout process. All fees and charges are non-refundable.
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                All fees and charges incurred in connection with your user name or password will be billed to the credit card you designate during the registration process for the Service or the electronic information service, which provides you access to the Service. If there are any annual, or similar periodic fees for your Service subscription, these fees will be billed automatically to your credit card at the start of the period, and at the start of each renewal period, unless you terminate your subscription before the relevant period begins. If you want to designate a different credit card or there is a change in your credit card validity or expiration date, or if you believe someone has accessed the Service using your user name or password without your authorization, you must call Aggregate Intelligence, Inc Customer Service at the telephone numbers posted on the website or fill up the form available in “Contact” page. You also are responsible for any fees or charges incurred to access the Service through an Internet access provider or other third party service.YOU, AND NOT Aggregate Intelligence, Inc, ARE RESPONSIBLE FOR PAYING ANY AMOUNTS BILLED TO YOUR CREDIT CARD BY A THIRD PARTY, WHICH WERE NOT AUTHORIZED BY YOU.
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                If Aggregate Intelligence, Inc and you or your employer has manually signed a paper version of a contract governing your use of the Service, or if you or your employer subscribe to the Service pursuant to an Enterprise Subscription Agreement, some or all of these "Fees and Payments" terms may not apply to you. Please contact your employer for details.
                                                                                    </Typography>
                                                                                            <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                COPYRIGHT AND LIMITATIONS ON USE
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                The content available as part of the Service, other than content accessible through the Product area, is defined as "Licensed Information". The Licensed Information is the property of Aggregate Intelligence, Inc or its licensors and is protected by copyright and other intellectual property laws. Licensed Information received through the Service may be displayed, reformatted and printed for your personal, non-commercial use only. You agree not to reproduce, transmit, distribute, display, sell, location, archive or publish any Licensed Information to anyone, including but not limited to others in the same company or organization, without the express prior consent of Aggregate Intelligence, Inc with these two exceptions:
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                <b>1)</b> You may, on an occasional and irregular basis, disseminate an insubstantial portion of content retrieved from the Service, for a non-commercial purpose, without charge, and in non-electronic form, to a limited number of individuals, provided you include all copyright and other proprietary notices with such portion of the content in the same form in which the notices appear in the Service, and the phrase "Used with permission from Aggregate Intelligence, Inc " To request consent for other matters, please send an e-mail to president@StorTrack.com
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                <b>2)</b> Except where indicated otherwise you may e-mail your Service search results to a single e-mail address and a single individual within your company or organization The content accessible through hyperlinks within company capsules in the database is not made available to you through any contractual arrangement between Aggregate Intelligence, Inc and third parties, but instead is made available from publicly available sources on the World Wide Web. FOR THIS REASON, Aggregate Intelligence, Inc DOES NOT GRANT YOU ANY RIGHT OR LICENSE WHATSOEVER WITH RESPECT TO CONTENT ACCESSIBLE THROUGH THE GUIDE. You are solely responsible for determining the extent to which the content accessible through the application may be used or distributed. Aggregate Intelligence, Inc reserves the right to monitor the Subscriber's use of all premium content, services, and tools to ensure the Subscriber's compliance with the Terms and Conditions. If it is determined that the Subscriber is not in compliance with these Terms and Conditions, Aggregate Intelligence, Inc reserves the right to take such action as is deemed necessary, including, but not limited to, suspension or termination of the Subscriber's account. The Subscriber acknowledges that such monitoring of use may include determining whether or not the Service is accessed under the account from IP addresses, monitoring the log files to determine if any computer programs are being run in order to access data from the site, as well as noting excessive use from Subscription accounts. Only one individual may access the Service at the same time using the same user name or password, unless agreed otherwise by Aggregate Intelligence, Inc.
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                Aggregate Intelligence, Inc and its content licensors do not give investment advice, or advocate the purchase or sale of any security or investment.
                                                                                    </Typography>
                                                                                            <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                DISCLAIMER OF WARRANTIES AND LIABILITY
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                Due to the number of sources from which content on the Service is obtained, and the inherent hazards of electronic distribution, there may be delays, omissions or inaccuracies in such content and the Service. Aggregate Intelligence, Inc AND ITS AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE CONTENT AVAILABLE THROUGH THE SERVICE OR THE SERVICE ITSELF. Aggregate Intelligence, Inc AND ITS AFFILIATES, AGENTS OR LICENSORS SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGE, OTHER THAN DEATH OR PERSONAL INJURY RESULTING DIRECTLY FROM USE OF THE SERVICE, CAUSED IN WHOLE OR PART BY CONTINGENCIES BEYOND THEIR CONTROL OR NEGLIGENCE IN PROCURING, COMPILING, INTERPRETING, REPORTING OR DELIVERING THE SERVICE AND ANY CONTENT THROUGH THE SERVICE. IN NO EVENT WILL Aggregate Intelligence, Inc, ITS AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH CONTENT. Aggregate Intelligence, Inc AND ITS AFFILIATES, AGENTS AND LICENSORS SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES OTHER THAN DIRECT DAMAGES (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES) EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT THE LIABILITY OF Aggregate Intelligence, Inc, ITS AFFILIATES, AGENTS AND LICENSORS, IF ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM (WHETHER IN CONTRACT, TORT OR OTHERWISE) IN ANY WAY CONNECTED WITH THE SERVICE OR THE CONTENT IN THE SERVICE SHALL NOT EXCEED THE AMOUNT YOU PAID TO Aggregate Intelligence, Inc FOR THE USE OF THE SERVICE IN THE 12 MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM.
                                                                                    </Typography>
                                                                                            <Typography variant="h5" component="h5" gutterBottom className="st-online">
                                                                                                ADDITIONAL LEGAL TERMS AND CONDITIONS
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                You are responsible for all statements made and acts or omissions that occur while your password is being used. You may not use the Service for any unlawful purpose. Aggregate Intelligence, Inc may refuse to grant you a user name that impersonates someone else, is protected by trademark or other proprietary right law, or is vulgar or otherwise offensive, as determined by Aggregate Intelligence, Inc.
                                                                                    </Typography>
                                                                                            <Typography gutterBottom>
                                                                                                Aggregate Intelligence, Inc may discontinue or change the Service, certain Licensed Information, or certain content within the Service or its availability to you, at any time, and you may always terminate your subscription at any time. This Agreement is personal to you, and you may not assign your rights or obligations to anyone. If any provision in this Agreement is invalid or unenforceable under applicable law, the remaining provisions will continue in full force and effect. This Agreement, your rights and obligations, and all actions contemplated by this Agreement shall be governed by the laws of the United States of America and the State of California, as if the Agreement was a contract wholly entered into and wholly performed within the State of California. This Agreement will not be governed by the United Nations Convention on Contracts for the International Sale of Goods.
                                                                                    </Typography>
                                                                            </DialogContent>
                                                                        </Dialog>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    <Col md="6" className="pr-0">
                                                        <Row className="height-94">
                                                            <Col>
                                                                <div className="bill-promo height-94 pr-2">
                                                                    <Paper className="p-2 pt-4 pb-4 elevation0 apply-promo height-94" component="div">
                                                                        <Typography variant="h5" component="h5" align="right" className="st-subscription" >
                                                                            Your Order Details
                                                                        </Typography>
                                                                        <Typography component="p" align="right" className=''>
                                                                            1 Market Report Credits w/ Explorer Access
                                                                        </Typography>
                                                                                                         
                                                                        <Typography component="p" align="right" gutterBottom>
                                                                            Total: <b>{total_value !== null ? <NumberFormat value={dtotal_value} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : curSymb + '0'}</b>
                                                                        </Typography>
                                                                        
                                                                        <Col className="padding-top-10 padding-right-0 margin-btm-10">
                                                                                <Typography variant="h6" component="h6" align="right">Promo code: {this.state.validCoupon ? <b>{this.state.validCoupon}</b> : '----'}</Typography>
                                                                        </Col>

                                                                        <Typography align="right" gutterBottom variant="h5" component="h5" className="st-subscription">
                                                                            Total Amount: <b className={this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? 'discount-strike' : ''}>{total_value !== null ? <NumberFormat value={dtotal_value} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b> <b>{this.state.discountTotal !== '' && this.state.discountTotal !== undefined ? <NumberFormat value={this.state.discountTotal} displayType={'text'} thousandSeparator={true} prefix={curSymb} /> : ''}</b>
                                                                        </Typography>
                                                                    </Paper>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="step_btn">
                                                            <Button
                                                                onClick={this.handleBack.bind(this)}
                                                                color="secondary"
                                                                variant="contained"
                                                                className="go-back-btn">
                                                                 Go Back
                                                            </Button>
                                                            <Button disabled={this.state.dblclkrest || this.state.dblclkrest === true ? true : false} className="next-btn">Pay Now</Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </Container>
                                    </div>
                                    :
                                    activeStep === 4 ?
                                        <div className="last-step">
                                            <Container>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="sec-msg">
                                                            <Paper className="p-3 elevation0" component="div" align="center" >
                                                                <Typography variant="h3" component="h3" className="pb-2 thank-you" >
                                                                    Thank you for your purchase!
                                                                </Typography>
                                                                <Typography variant="h6" component="h6">
                                                                    You will get an order confirmation email shortly.
                                                                </Typography>
                                                                <Typography variant="body2" className="fin-txt">
                                                                    nSite Market Reports may take up to several minutes to generate. When ready, click on the Download Link to view the PDF report.
                                                                    Historical Pricing Reports are available within one business day from day of purchase. A link will be sent to you to download the report in Excel.<br/><br/>
                                                                    If you need to close this window, you can re-open it by clicking the link in the email confirmation sent to you.
                                                                </Typography>
                                                                <Divider />
                                                                <Typography component="p" className="pt-2 mb-0 fnt-14 opacity-7" gutterBottom>
                                                                    Questions? Contact us at 1-800-969-7424 or
                                                                </Typography>
                                                                <Typography component="p" gutterBottom classname="fnt-14">
                                                                    <a href={`mailto:support@stortrack.com`} className="email-conform">support@stortrack.com</a>
                                                                </Typography>
                                                            </Paper>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>            
                                    :
                                    '' } 
                                </div>             
                            </DialogContent>
                        </Dialog>   
                        
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={snackOpen}
                            className="snackbar bg-color-primary custom-snackbar"
                            maxsnack={3}
                            autoHideDuration={3000}
                            onClose={this.snackHandleclose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{snackMSg}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={this.snackHandleclose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ]}
                        />                           
                    </Container>
                </section>
            </div>
        );
    }
}