import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Container } from 'reactstrap';
import { CONFIG } from '../../utils/config';
import axios from 'axios';
import { HashLink as Link } from 'react-router-hash-link';
import './BuyReport.css';
import PaymentModel from '../PaymentModel/PaymentModel.js';
import NumberFormat from 'react-number-format';
import Slider from '@material-ui/core/Slider';
import PAYG_PaymentModel from '../PaymentModel/PAYG_PaymentModel.js';
import IconButton from '@material-ui/core/IconButton';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


export default class BuyReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            no_comp: '',
            scale: CONFIG.DefaultRadius,
            inv_of_mark: '',
            pop_serv: '',
            med_hos_inc: '',
            sq_cap: '',
            pr_trnd: '',
            RepPrice: 0,
            Sub_mrk_name: '',
            locationView: {},
            userStoreID: this.props.match.params.UserStoreId ? this.props.match.params.UserStoreId : '',
            latitude: '',
            longitude: '',
            marketChoice: this.props.match.params.MarketType ? this.props.match.params.MarketType : '',
            searchType:  0,
            marketName: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            isReportId:1,
            addressFormat : '',
            repAddressDet: '',
            isPayAsyouGo: '',
            vidModal: false,
        };

        this.radiusOnChange = this.radiusOnChange.bind(this);
        this.child = React.createRef();
        this.childPAYG = React.createRef();
        this.openVidModal = this.openVidModal.bind(this);
        this.closeVidModal = this.closeVidModal.bind(this);
    }

    //Open sample video modal 
    openVidModal() {
        this.setState({ vidModal: true });
    }

    //Close sample video modal 
    closeVidModal() {
        this.setState({ vidModal: false });
    }


    OpenPaymentModel() {
        this.child.current.openDialog(this.state.RepPrice, this.props.match.params.SecureId, this.props.match.params.UserStoreId, this.state.isReportId, this.state.addressFormat);
    }
    radiusOnChange(scalevalue, value) {
        let scale = value;
        // this.setState({ scale, isLoading: true }, () => { this.searchLocation() });
        this.setState({ scale, isLoading: true });
        this.searchLocation();
    }
    searchLocation() {

        let data = {

            UserStoreID: this.state.userStoreID,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zipCode: this.state.zipCode,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            Radius: this.state.scale,
            RegionId: CONFIG.Region_Id,
            IsEdit: 1,
            MarketChoice:this.state.marketChoice

        }
        
        axios.post(CONFIG.API_URL + 'marketchoice/searchbylocation', data)
            .then(response => {
                
                let locationStoreList = response.data.locationStoreList;
                this.saveCoverage(this.state.userStoreID);
                // this.setState({ isLoading: false });
                // this.setState({ locationStoreList, isLoading: false }, () => { this.saveCoverage(this.state.userStoreID) });
            })
            .catch((err) => {
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else if (err.response.status === 404) {
                    this.setState({ snackOpen: true, snackMSg: 'Location not found' });
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    saveCoverage(userStoreId) {
        var data = {
            MarketName: this.state.marketName,
            UserStoreId: userStoreId,
            Radius: this.state.scale,
            SearchType: this.state.searchType,
            RegionId: CONFIG.Region_Id,
            IsEdit: 1,
            IsMarketViewUserStore: true
        }

        axios.post(CONFIG.API_URL + 'marketchoice/savemarketcoverage', data)
            .then(response => {
                
                let isSuccess = response.data.result;
                if (isSuccess = "Success") {
                    this.searchReportData();
                }

                // this.setState({ isLoading: false });
            })
            .catch((err) => {
                
                this.setState({ isLoading: false });
                if (err.response.status === 401) {
                    localStorage.removeItem('accessKey');
                    const { from } = this.props.location.state || { from: { pathname: "/" } };
                    this.props.history.push(from);
                }
                else {
                    alert(err.response.data);
                }
            });
    }

    componentDidMount() {
        
        //Get Price
        axios.get(CONFIG.API_URL + 'home/getpricecalculation/' + CONFIG.Region_Id)
            .then(response => {
                let price = response.data.pricedet.priceList;
                let getRepPrice = price.filter(function (data) { return data.userType === 6 && data.reportID === 1 });

                let RepPrice = getRepPrice.length > 0 ? getRepPrice[0].regularPrice : 0;
                this.setState({ RepPrice });
            })
            .catch(err => {
                alert(err);
            });

        //Get Market Address
        let data = { UserStoreId: this.props.match.params.UserStoreId };
        axios.post(CONFIG.Local_Host + 'marketaddress/searchreportdata', data)
        .then(response => {

        let addressDetail = response.data.address.addressInformation;
        let addressFormat = '', repAddressDet = '';
        if(this.props.match.params.MarketType === '3' || this.props.match.params.MarketType === '4'){
            addressFormat = addressDetail.storeName ? addressDetail.storeName : '';
        }
        else{
            if(addressDetail.city || addressDetail.zipcode){
                addressFormat = addressDetail.address ? addressDetail.address + ',<br/>' : '';
                addressFormat += addressDetail.address2 ? addressDetail.address2 + ',<br/>' : '';
                addressFormat += addressDetail.city ? addressDetail.city + ', ' : '';
                addressFormat += addressDetail.state ? addressDetail.state + ' ' : '';
                addressFormat += (addressDetail.zipcode ? addressDetail.zipcode : '');
                //report Address details showing
                repAddressDet = "Market: "+addressFormat;
            }
            else{
                addressFormat = addressDetail.latitude ? 'Coordinates: '+addressDetail.latitude + ', ' : '';
                addressFormat += addressDetail.longitude ? addressDetail.longitude : '';
                //report Address details showing
                repAddressDet = "Geo "+addressFormat;
            }

        }
            
            this.setState({ addressFormat, repAddressDet });
        })
        .catch(err => {
            this.setState({ isLoading: false });
            alert(err);
        });

        this.getUserInfo();
        this.searchReportData();

        //to know buying report/market
        let getpathName = window.location.pathname.split('/');
        let isPAYGvalid = [];
        for(var i = 0; i < getpathName.length; i++){
            if(getpathName[i] === 'getonemarket'){
                isPAYGvalid.push(true);
            }
        }
        this.setState({ isPayAsyouGo : isPAYGvalid.length > 0 ? true : false })
    }

    getUserInfo() {
        
        let data = { UserStoreId: this.props.match.params.UserStoreId,RegionId:CONFIG.Region_Id };
        // let token = localStorage.getItem('accessKey');
        //     if (token) {
        //         axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        //     }
        axios.post(CONFIG.API_URL + 'marketchoice/userstoreinfo', data)
            .then(response => {

                let result = response.data;
                let address=result.address;
                let city=result.city;
                let state=result.state;
                let zipCode=result.zipcode;
                let latitude=result.latitude;
                let longitude=result.longitude;
                let searchType=result.searchType;

                
                this.setState({ address,city,state,zipCode,latitude,longitude,searchType });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                alert(err.response.data);
                // if (err.response.status === 401) {
                //     localStorage.removeItem('accessKey');
                //     const { from } = this.props.location.state || { from: { pathname: "/" } };
                //     this.props.history.push(from);
                // }
                // else {
                //     alert(err.response.data);
                // }
            })

    }


    searchReportData() {
        
        //Get Report data
        let data = { UserStoreId: this.props.match.params.UserStoreId };
        axios.post(CONFIG.API_URL + 'marketchoice/searchreportdata', data)
            .then(response => {

                let no_comp = response.data.locationReportOverview.numberOfCompetitors;
                let inv_of_mark = response.data.locationReportOverview.inventoryOffMarket;
                let pop_serv = response.data.locationReportOverview.population;
                let med_hos_inc = response.data.locationReportOverview.medianHouseHoldIncome;
                let sq_cap = response.data.locationReportOverview.sqftCapita;
                
                let pr_trnd = response.data.locationReportOverview.priceTrend;
                //pr_trnd=pr_trnd>0?'+'+pr_trnd:pr_trnd;
                // let Sub_mrk_name = response.data.marketName + '  ' + response.data.marketType;
                let Sub_mrk_name = response.data.locationReportOverview.marketName;
                let marketName = response.data.locationReportOverview.marketName;
                
                this.setState({ no_comp, inv_of_mark, pop_serv, med_hos_inc, sq_cap, pr_trnd, Sub_mrk_name, isLoading: false, marketName });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                alert(err);
            });
    }

    OpenPAYGPaymentModel(){
        let Sec_Id = this.state.Sec_Id != null && this.state.Sec_Id ? this.state.Sec_Id : localStorage.getItem('IsSecureId');
        this.childPAYG.current.openDialog(1, this.state.RepPrice, this.props.match.params.SecureId, this.props.match.params.UserStoreId, this.state.isReportId );
    }


    render() {
        const { isLoading, no_comp, inv_of_mark, pop_serv, med_hos_inc, sq_cap, pr_trnd,
            Sub_mrk_name, RepPrice, searchType, marketChoice, repAddressDet, isPayAsyouGo, vidModal } = this.state;
        return (
            <div className="rr-home">
                {isLoading ? <div className="loader-wrap only-this-page"><div className="loading loading-center"></div></div> : ''}
                <div className="background-header" style={{height: 'auto'}}>
                    <div class="overlay-bg flat"></div>
                    <h1 className="title-medium text-white" style={{padding: '45px 0px'}}>
                        The data you need to make smart, informed investment decisions
                    </h1>
                </div>
                <div className="content-section mt-4">
                    <Container>
                        <section>
                            {/* { isPayAsyouGo ? ''
                            :
                                <Row>
                                    <Col md={10}>
                                        <h4>StorTrack Market Reports deliver unique data analysis on each market, providing<br />
                                            critical due diligence insights for any buyer or investor
                                        </h4>
                                    </Col>
                                </Row>
                            } */}
                            <Row className="pt-2 remove-para-padding">
                                <Col md={4} className="p-0">
                                    <div className="each-report assign-left-none">
                                        {/* <h6>{isPayAsyouGo ? 'What you Get:' : 'What is in each Report:' }</h6> */}
                                        <h6>What you Get:</h6>
                                        {/* { isPayAsyouGo ?
                                            <React.Fragment> */}
                                                <p>Access over 19 interactive data widgets to investigate all aspects of the market</p>
                                                <p>Interactive maps, lists, developments, demographics, spend info</p>
                                                <p>Three years rate and availability at the store level</p>
                                                <p>Comparison tools for side-by-side analysis (1/3/5 mile)</p>
                                                <p>PLUS a flexible Report Builder so you can build your own reports and download. Full customization!</p>
                                            {/* </React.Fragment>
                                        :
                                            <React.Fragment>
                                                <p>Every competitor store with full profile</p>
                                                <p>Total rentable square feet in market</p>
                                                <p>Market square feet per capita (with comparisons)</p>
                                                <p>3 year rate history, by unit type (incl. averages)</p>
                                                <p>3 year inventory history, by unit type</p>
                                                <p>Market demographics (population, income, property)</p>
                                                <p>A list of all known current developments in the market</p>
                                                <p>Price trend history</p>
                                                <p>Inventory trend history</p>
                                                <p>Forecast supply change in next 12 months</p>
                                            </React.Fragment>
                                        } */}
                                    </div>
                                    <div className="btns-bottom text-center view-sample-btns">
                                        {/* <Button className="all-reports sample-reports">View Sample</Button> */}
                                        {/* {isPayAsyouGo ? */}
                                            <a onClick={this.openVidModal.bind(this)} style={{color: '#fff'}} class="all-reports sample-reports btn btn-secondary">View Video Sample</a>
                                        {/* :
                                            <a href="javascript:window.open('/PdfFile/MarketReport.pdf','_blank')" class="all-reports sample-reports btn btn-secondary">View Sample</a>
                                        } */}
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className="text-center pl-3 pr-3 pb-3 pt-0">
                                        <div className='report-details'>
                                            <div className="selected-lists">
                                                <h5 style={{marginBottom: '5px'}}>Your Selected {isPayAsyouGo ? 'Market' : 'Market'}</h5>
                                                    {repAddressDet ? 
                                                        <p><b>{repAddressDet.replace(/<br\s*\/?>/gi,' ')}</b></p>
                                                    :
                                                    ''}
                                                {marketChoice === "1" || marketChoice === "2" ?
                                                    <div>

                                                        <div className="radius-label mb-2">{this.state.scale} mile radius (change by moving slider)</div>
                                                        <Slider className="r-slider"
                                                            defaultValue={this.state.scale}
                                                            valueLabelDisplay='on'
                                                            // valueLabelFormat={this.getcustomLabel}
                                                            onChangeCommitted={this.radiusOnChange}
                                                            aria-labelledby="discrete-slider-always"
                                                            step={1}
                                                            marks={[{
                                                                value: 1,
                                                                label: '1'
                                                            },
                                                            {
                                                                value: 2,
                                                                label: '2'
                                                            },
                                                            {
                                                                value: 3,
                                                                label: '3'
                                                            },
                                                            {
                                                                value: 4,
                                                                label: '4'
                                                            },
                                                            {
                                                                value: 5,
                                                                label: '5'
                                                            },
                                                            {
                                                                value: 6,
                                                                label: '6'
                                                            },
                                                            {
                                                                value: 7,
                                                                label: '7'
                                                            },
                                                            {
                                                                value: 8,
                                                                label: '8'
                                                            },
                                                            {
                                                                value: 9,
                                                                label: '9'
                                                            },
                                                            {
                                                                value: 10,
                                                                label: '10'
                                                            }
                                                            ]}
                                                            min={1}
                                                            max={10}
                                                        />   </div> : ''}
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">
                                                                Number of Competitors
                                                        </div>
                                                            <div className="values2">
                                                                {isLoading ? '' : no_comp ? <NumberFormat value={no_comp} displayType={'text'} thousandSeparator={true} /> : isLoading === false ? 'N/A' : ''}
                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">
                                                                Inventory Off Market
                                                        </div>
                                                            <div className="values2">
                                                            <a href="javascript:void(0);" onClick={isPayAsyouGo ? this.OpenPAYGPaymentModel.bind(this) : this.OpenPaymentModel.bind(this)}>Buy Report</a>
                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">
                                                                Population Served
                                                        </div>
                                                            <div className="values2">
                                                            <a href="javascript:void(0);" onClick={isPayAsyouGo ? this.OpenPAYGPaymentModel.bind(this) : this.OpenPaymentModel.bind(this)}>Buy Report</a>
                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">
                                                                Median Household Income
                                                        </div>
                                                            <div className="values2">
                                                            <a href="javascript:void(0);" onClick={isPayAsyouGo ? this.OpenPAYGPaymentModel.bind(this) : this.OpenPaymentModel.bind(this)}>Buy Report</a>
                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">
                                                                Sqft/Capita
                                                        </div>
                                                            <div className="values2">
                                                                {isLoading ? '' : sq_cap ? <NumberFormat value={sq_cap} displayType={'text'} thousandSeparator={true} /> : isLoading === false ? 'N/A' : ''}
                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">
                                                                No. of Current Developments in Market
                                                        </div>
                                                            <div className="values2">
                                                                <a href="javascript:void(0);" onClick={isPayAsyouGo ? this.OpenPAYGPaymentModel.bind(this) : this.OpenPaymentModel.bind(this)}>Buy Report</a>
                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">
                                                            Rate Trend (last 12 months)
                                                        </div>
                                                            <div className="values2">
                                                                {/* {isLoading ? '' : pr_trnd ? <NumberFormat value={pr_trnd} displayType={'text'} thousandSeparator={true} suffix={'%'}/> : 'N/A'} */}
                                                                {isLoading ? '' : pr_trnd ? pr_trnd > 0 ? '' + pr_trnd + '%' : pr_trnd + '%' : isLoading === false ? 'N/A' : ''}

                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="selected-reports-section">
                                                            <div className="values1">
                                                                Supply Growth Forecast (next 12 months)
                                                            </div>
                                                            <div className="values2">
                                                                <a href="javascript:void(0);" onClick={isPayAsyouGo ? this.OpenPAYGPaymentModel.bind(this) : this.OpenPaymentModel.bind(this)}>Buy Report</a>
                                                            </div>
                                                            <div className="clear"></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="get-reports">
                                            <Row className="pt-0 p-3">
                                                {/* {isPayAsyouGo ?  */}
                                                    <Col>
                                                        <div className="btns-bottom text-center">
                                                            <Button className="all-reports sample-reports pl-0 pr-0 width-change" onClick={this.OpenPaymentModel.bind(this)}>Buy Market Report @ <NumberFormat value={ RepPrice } displayType={'text'} thousandSeparator={true} prefix={CONFIG.Currency_Symbol} /> PLUS Data Access</Button>
                                                        </div>
                                                    </Col>
                                                {/* :
                                                    <React.Fragment>
                                                        <Col md={8}><h5>Buy this Report now for: <NumberFormat value={RepPrice + '.00'} displayType={'text'} thousandSeparator={true} prefix={CONFIG.Currency_Symbol} /></h5></Col>
                                                        <Col md={4}>
                                                            <div className="btns-bottom text-center">
                                                                <Button className="all-reports sample-reports" onClick={this.OpenPaymentModel.bind(this)}>Buy Report</Button>
                                                            </div>
                                                        </Col>
                                                    </React.Fragment>
                                                } */}
                                            </Row>
                                            {/* <Row className="pt-0 p-3">
                                                <Col md={8}><h5>Or buy Report Tokens to save money!</h5></Col>
                                                <Col md={4}>
                                                    <div className="btns-bottom text-center">
                                                        <Button className="all-reports sample-reports">3 Tokens @</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="pt-0 p-3">
                                                <Col md={8}><p>Pre-buy report tokens and you will receive a code to use for that many Reports</p></Col>
                                                <Col md={4}>
                                                    <div className="btns-bottom text-center">
                                                        <Button className="all-reports sample-reports">10 Tokens @</Button>
                                                    </div>
                                                </Col>
                                            </Row> */}
                                        </div>                                                                                 
                                    </div>
                                </Col>
                            </Row>
                        </section>
                    </Container>
                </div>
                {/* Sample Video Modal */}
                <Dialog
                    open={vidModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="sample-videos"
                >
                    <DialogTitle>
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.closeVidModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <iframe width="550" height="315" src="https://www.youtube.com/embed/QadKbv_WyUw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </DialogContent>
                </Dialog>
                <div className="payment-hide">

                    {/* Pay-As-you-Go Payment Dialog Box */}
                        {isPayAsyouGo ? 
                            < PAYG_PaymentModel parm={this.props} ref={this.childPAYG} /> 
                        : 
                            <PaymentModel parm={this.props} ref={this.child} />
                        }
                    {/* End of Pay-As-you-Go Payment Dialog Box */}

                </div>
            </div>
        );
    };
}