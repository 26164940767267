import React from 'react';
import RouteComp from './Router/Router'; 

class App extends React.Component{
  render(){
    return (
      <div className="App">
          <RouteComp/>
      </div>
    );
  }
}

export default App;
