import React from 'react';
import { Container, Row, Col} from 'reactstrap';
import { CONFIG } from '../../utils/config';
import './ErrorPages.css'
import UnAuthorized from './../../Assets/Images/stop_hand.png';

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          time: 5
        };
      }
      componentDidMount() {
        if(this.props.match.path === '/page_expired'){
          this.intervalID = setInterval(
            () => this.tick(),
            1000
          );
        }
      }
      componentWillUnmount() {
        clearInterval(this.intervalID);
      }
      tick() {
        if(this.state.time > 0){
            this.setState({
                time: this.state.time - 1
              });
        }
        else{
          this.props.loginToken();
          window.top.location.href = CONFIG.STK_SIGNURL;
        }
        
      }

    render(){
        return(
            <div className="SessionOut pt-5 pb-5 text-center mt-5 mb-5">
                <Container>
                    <Row>
                        <Col>
                            <div className="SO_Content">
                              {this.props.match.path === '/page_expired' ? 
                                  <React.Fragment>
                                    <h2 className="err_head">Your session has expired, we will redirect to the home page within</h2>
                                    <span className="clr_code">{this.state.time} seconds...</span>
                                  </React.Fragment>
                              :
                              this.props.match.path === '/UnauthorizedAccess' ? 
                                  <React.Fragment>
                                    <div>
                                      <img src={UnAuthorized}/>
                                    </div>
                                    <div>
                                        <h1 className="err_head">
                                            <span class="name clr_code">Access is denied</span>
                                        </h1>                 
                                        <h3 className="err_desc">
                                          You do not have permission to view this page using the credentials that you supplied.
                                      </h3>
                                    </div>
                                    <div class="Biblio err_desc pt-1" align="center">
                                        If you require any assistance or support please contact us at <b>support@stortrack.com</b> or <b>1-800-969-7424</b>.
                                        <br/>
                                        You can click on any visible link on top of this page to continue accessing the site.
                                    </div>
                                  </React.Fragment>
                              :
                                    <fieldset className={localStorage.getItem('accessKey') ? 'mt-5' : ''}>
                                      <h2 className='err_head clr_code'>404 - File or directory not found.</h2>
                                      <h3 className="err_desc">The resource you are looking for might have been removed, had its name changed, or is temporarily unavailable.</h3>
                                    </fieldset>
                              }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

}

export default ErrorPage;