import React, { Component } from 'react';
import './SuccessPage.css';
import { Container, Col, Row, Button } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import { HashLink as Link } from 'react-router-hash-link';
import { CONFIG } from '../../utils/config';

export default class SuccessPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            cartDetails: [],
            getdownloadStatus: 'success'
        };
        this.ReportCart = this.ReportCart.bind(this);
    }

    componentDidMount() {

        //Get cart details
        axios.get(CONFIG.Local_Host + 'subscription/reportscart/' + this.props.match.params.SecureId) //'http://52.37.198.165:1213/api/subscription/add
            .then(response => {
                let cartDetails = response.data;
                let getdownloadStatus = response.data.length > 0 ? response.data[0].downloadStatus ? response.data[0].downloadStatus.toString().toLowerCase() : 'success' : 'success';
                this.setState({ cartDetails, isLoading: false, getdownloadStatus });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                alert(err);
            });

        // To call the API for every 15 mintues
        this.intervalID = setInterval(
            () => this.ReportCart(), 15000
        );

    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    //To get cart details every 15 seconds till dowmload status become success
    ReportCart() {
        if (this.state.getdownloadStatus === 'not yet started' || this.state.getdownloadStatus === 'inprogress') {
            axios.get(CONFIG.Local_Host + 'subscription/reportscart/' + this.props.match.params.SecureId) //'http://52.37.198.165:1213/api/subscription/add
                .then(response => {
                    let cartDetails = response.data;
                    let getdownloadStatus = response.data.length > 0 ? response.data[0].downloadStatus ? response.data[0].downloadStatus.toString().toLowerCase() : 'success' : 'success';
                    this.setState({ cartDetails, isLoading: false, getdownloadStatus });
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    alert(err);
                });
        }
    }

    //To Download File
    downloadFile(displayFileName) {
        this.setState({ isLoading: true });
        axios({ url: CONFIG.Local_Host + 'subscription/downloadFile/' + displayFileName, method: 'get', responseType: 'blob', })
            .then((response) => {

                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = displayFileName;
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(link.href);
                    link.remove();
                }, 3000);
                this.setState({ isLoading: false });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                alert(err);
            });
    }

    render() {
        const { isLoading, cartDetails } = this.state;
        return (
            <div className="last-step">
                {isLoading ? <div className="loader-wrap"><div className="loading loading-center"></div></div> : ''}
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-msg">
                                <Paper className="p-3 elevation0" component="div" align="center" >
                                    <Typography variant="h3" component="h3" className="pb-2 thank-you" >
                                        Thank you for your purchase!
                                </Typography>
                                    <Typography variant="h6" component="h6">
                                        You will get an order confirmation email shortly.
                                </Typography>
                                    <Typography variant="body2" className="fin-txt">
                                        <p style={{ marginBottom: '5px' }}>Market Reports may take up to several minutes to generate. When ready, click on the Download Link to view the PDF report.
                                    {/* Historical Pricing Reports are available within one business day from day of purchase. A link will be sent to you to download the report in Excel. */}</p>
                                        <p>If you need to close this window, you can re-open it by clicking the link in the email confirmation sent to you.</p>
                                    </Typography>
                                    <div className="promoCode_btn pt-2 pb-0">
                                        <Table className="table table-bordered custom-table payment-table" style={{ marginBottom: '10px' }}>
                                            <TableHead>
                                                <TableRow className="table-header">
                                                    <TableCell>Report Type</TableCell>
                                                    <TableCell>Market</TableCell>
                                                    <TableCell>Details</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Download link</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {cartDetails.length > 0 ? cartDetails.map((data, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>{data.reportType}</TableCell>
                                                            <TableCell>{data.isCoordinates === 1 ? 'Coordinates: ' + data.location : data.location}</TableCell>
                                                            <TableCell>{data.details}</TableCell>
                                                            <TableCell>{data.downloadStatus == 'InProgress' ? 'In Progress' : data.downloadStatus}</TableCell>
                                                            <TableCell >  {data.downloadStatus == 'Success' ? <a href="javascript:void(0);" className={isLoading ? 'disabled-link' : ''} onClick={this.downloadFile.bind(this, data.displayFileName)}> Click Here </a> : data.downloadStatus == 'InProgress' ? <span className="loader-xcel"></span> : ' --- '}</TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                                    :
                                                    <TableRow>
                                                        <TableCell colSpan={5} className="no-data" >{isLoading ? '' : 'Cart details not available'}</TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <Typography component="p" className="pt-2 mb-0" style={{ paddingBottom: '15px', textAlign: 'left', fontSize: '14px' }} gutterBottom>
                                        <b style={{ display: 'block', marginBottom: '5px' }}>Don’t Forget! Your purchase also includes access to the Explorer platform</b> for 30 days where you can access additional market information For access, click below to sign in!
                                </Typography>
                                    <Typography component="p" gutterBottom classname="fnt-14">
                                        <a href={CONFIG.StortrackLogin} target="_blank" class="all-reports sample-reports btn btn-secondary go-to-mvp">Go to my Explorer Account</a>
                                    </Typography>
                                    <Divider />
                                    <Typography component="p" className="pt-2 mb-0 fnt-14 opacity-7" gutterBottom>
                                        {/* Questions? Contact us at 1-800-969-7424 or */}
                                        Questions? Contact us at {CONFIG.Sales} or
                                </Typography>
                                    <Typography component="p" gutterBottom classname="fnt-14">
                                        {/* <a href={`mailto:support@stortrack.com`} className="email-conform">support@stortrack.com</a> */}
                                        <a href={CONFIG.Mail_Support} className="email-conform">{CONFIG.Mail_Support}</a>
                                    </Typography>

                                </Paper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}